import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import App from './App';

import './i18n';

import configureStore from './store/store';
import {ConfigProvider} from "antd";

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider theme={{
                            token: {
                                // Seed Token
                                // colorPrimary: '#6699ff',
                                //borderRadius: 2,

                                // Alias Token
                                //colorBgContainer: '#f6ffed',
                            },
                        }}
        >
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();