import gpl from "graphql-tag";

export const productParentField = gpl`
  fragment productParentField on ProductType{
    productId
    productSku
    productName
    productImage
  }
`;

export const productFieldDefault = gpl`
  fragment productFieldDefault on ProductType{
    productId
    productSku
    productBarcode
    productName
    productShortName
    productImage
    productBuyPrice
    productBuyAvailable
    productSellPrice
    productWholesalePrice
    productSellAvailable
    productDescription
    productUnit{
        unitId
        unitName
    }
    productShelve{
        shelveId
        shelveName
    }
    productBrand{
        brandId
        brandName
    }
    productSupplier{
        supplierId
        supplierName
    }
    productType
    productMinimumInventory
    productMaximumInventory
    productWarehouseDefault{
        warehouseId
        warehouseName
    }
    productTrackInventory
    productCategory{
        categoryId
        categoryName
    }
    pqProduct{
        pqId
        pqProductMaterial{
            productId
            productName
        }
        pqProductMaterialUnit{
            unitId
            unitName
        }
        pqQuantity
        pqStatus
    }
    phaProduct{
      phaId
      phaAttribute{
        attrId
        attrName
        attrDatatype
      }
      paIntFromAttribute{
        paIntValue
      }
      paDecimalFromAttribute{
        paDecimalValue
      },
      paCharFromAttribute{
        paCharValue
      },
      paTextFromAttribute{
        paTextValue
      },
      paDatetimeFromAttribute{
        paDatetimeValue
      }
    }
    pcuProduct{
      pcuId
      pcuUnitRoot{
        unitId
        unitName
      }
      pcuUnitConversion{
        unitId
        unitName
      }
      pcuCalculation
      pcuRate
      pcuPrice
      pcuSellPrice
      pcuBonusPoint
      pcuBarcode
      pcuDescription
    }
    pgProduct{
        pgId
        pgImage
        pgDescription
        pgSerial
    }
    ppkProduct{
        ppkId
        ppkPriceBook{
            pbkId
            pbkName
        }
        ppkPrice
    }
    productWeight
    productSizeLength
    productSizeWidth
    productSizeHeight
    productExp
    productOrigin{
        countryId
        countryCode
        countryName
    }
    productSerialImeiEnable
    productAccumulatePointsEnable
    productBonusPoint
    productShipmentEnable
    productEnable
    webEnable
    webFeatured
    webBestseller
    productUserCreated{
        username
        firstName
        lastName
    }
  }
`;

export const productFieldSimple = gpl`
  fragment productFieldSimple on ProductType{
    productId
    productSku
    productName
    productImage
  }
`;

export const productReceivingField = gpl`
  fragment productReceivingField on ProductType{
    productId
    productSku
    productName
    productImage
    pcuProduct{
        pcuId
        pcuUnitRoot{
            unitId
            unitName
        }
        pcuUnitConversion{
            unitId
            unitName
        }
        pcuCalculation
        pcuRate
        pcuPrice
        pcuSellPrice
        pcuBarcode
        pcuBonusPoint
        pcuDescription
    }
  }
`;

export const productInvoiceField = gpl`
  fragment productInvoiceField on ProductType{
    productId
    productSku
    productName
    productImage
    productBuyPrice
    productSellPrice
    productUnit{
        unitId
        unitName
    }
    pcuProduct{
        pcuId
        pcuUnitRoot{
            unitId
            unitName
        }
        pcuUnitConversion{
            unitId
            unitName
        }
        pcuCalculation
        pcuRate
        pcuPrice
        pcuSellPrice
        pcuBarcode
        pcuBonusPoint
        pcuDescription
    }
  }
`;
