import {Affix, Layout, Menu} from 'antd';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    BarChartOutlined,
    DashboardOutlined, DatabaseOutlined, DollarOutlined,
    FundProjectionScreenOutlined, GoldOutlined, ReconciliationOutlined,
    SettingOutlined, ShoppingCartOutlined, ShoppingOutlined, SolutionOutlined, TeamOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../store/actions/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const { Sider } = Layout;

const rootSubmenuKeys = [
    'dashboard', 'sale', 'inventory', 'finance', 'marketing', 'hrm', 'crm', 'debt', 'report', 'system'
];

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const LeftSidebar = (props) => {
    const { t } = useTranslation();
    const [openKeys, setOpenKeys] = useState(['dashboard']);
    const [height, setHeight] = useState(600);

    const branchActivate = props.user.branchActivate;

    const items = [
        getItem(t('navigation.dashboard'), 'dashboard', <DashboardOutlined />),
        {type: 'divider',},
        getItem(t('navigation.sale.name'), 'sale', <ShoppingCartOutlined />, [
            getItem(t('navigation.sale.invoice'), 'sale.invoice'),
            ['F&B', 'K&B', 'HOTEL'].includes(branchActivate.branchTypeOfBusiness.tobCode) && getItem(t('navigation.sale.area'), 'sale.area'),
            ['F&B', 'K&B', 'HOTEL'].includes(branchActivate.branchTypeOfBusiness.tobCode) && getItem(t('navigation.sale.table'), 'sale.table'),
            //getItem(t('navigation.sale.counter'), 'sale.counter'),
            getItem(t('navigation.sale.priceBook'), 'sale.priceBook'),
        ]),
        /*getItem(t('navigation.purchase.name'), 'purchase', <ShoppingOutlined />, [
            getItem(t('navigation.purchase.invoices'), 'purchase.invoices'),
            getItem(t('navigation.purchase.create'), 'purchase.create'),
            getItem(t('navigation.purchase.return'), 'purchase.return'),
        ]),*/
        getItem(t('navigation.inventory.name'), 'inventory', <DatabaseOutlined />, [
            getItem(t('navigation.inventory.stock'), 'inventory.stock'),
            getItem(t('navigation.inventory.audit'), 'inventory.audit'),
            getItem(t('navigation.inventory.product'), 'inventory.product'),
            getItem(t('navigation.inventory.productCategory'), 'inventory.productCategory'),
            getItem(t('navigation.inventory.unit'), 'inventory.unit'),
            getItem(t('navigation.inventory.warehouse'), 'inventory.warehouse'),
            getItem(t('navigation.inventory.brand'), 'inventory.brand'),
            //getItem(t('navigation.inventory.attribute'), 'inventory.attribute'),
            getItem(t('navigation.inventory.barcode'), 'inventory.barcode'),
            getItem(t('navigation.inventory.shelves'), 'inventory.shelves'),
        ]),
        getItem(t('navigation.finance.name'), 'finance', <DollarOutlined />, [
            getItem(t('navigation.finance.cashBook'), 'finance.cashBook'),
            //getItem(t('navigation.finance.cashBookMoney'), 'finance.cashBookMoney'),
            //getItem(t('navigation.finance.cashBookBank'), 'finance.cashBookBank'),
            getItem(t('navigation.finance.cashier'), 'finance.cashier'),
            getItem(t('navigation.finance.receiptsType'), 'finance.receiptsType'),
            getItem(t('navigation.finance.paymentsType'), 'finance.paymentsType'),
            getItem(t('navigation.finance.coffer'), 'finance.coffer'),
            getItem(t('navigation.finance.fund'), 'finance.fund'),
            //getItem(t('navigation.finance.person'), 'finance.person'),
        ]),
        getItem(t('navigation.marketing.name'), 'marketing', <FundProjectionScreenOutlined />, [
            getItem(t('navigation.marketing.campaign'), 'marketing.campaign'),
            getItem(t('navigation.marketing.voucher'), 'marketing.voucher'),
            getItem(t('navigation.marketing.coupon'), 'marketing.coupon'),
            getItem(t('navigation.marketing.point'), 'marketing.point'),
            getItem(t('navigation.marketing.accumulation'), 'marketing.accumulation'),
        ]),
        getItem(t('navigation.hrm.name'), 'hrm', <SolutionOutlined />, [
            getItem(t('navigation.hrm.staffs'), 'hrm.staff'),
            getItem(t('navigation.hrm.positions'), 'hrm.position'),
            getItem(t('navigation.hrm.divisions'), 'hrm.division'),
            getItem(t('navigation.hrm.workType'), 'hrm.workTypes'),
            //getItem(t('navigation.hrm.workHour'), 'hrm.workHours'),
            //getItem(t('navigation.hrm.leave'), 'hrm.leave'),
            //getItem(t('navigation.hrm.leaveType'), 'hrm.leaveType'),
            //getItem(t('navigation.hrm.allowances'), 'hrm.allowances'),
            //getItem(t('navigation.hrm.deductions'), 'hrm.deductions'),
            //getItem(t('navigation.hrm.violationPenaltyType'), 'hrm.violationPenaltyType'),
            //getItem(t('navigation.hrm.timeTracking'), 'hrm.timeTracking'),
            //getItem(t('navigation.hrm.salaryConfig'), 'hrm.salaryConfig'),
        ]),
        getItem(t('navigation.crm.name'), 'crm', <TeamOutlined />, [
            getItem(t('navigation.crm.customers'), 'crm.customer'),
            getItem(t('navigation.crm.suppliers'), 'crm.supplier'),
            getItem(t('navigation.crm.customerGroup'), 'crm.customerGroup'),
            getItem(t('navigation.crm.supplierGroup'), 'crm.supplierGroup'),
        ]),
        getItem(t('navigation.debt.name'), 'debt', <ReconciliationOutlined />),
        /*getItem(t('navigation.asset.name'), 'asset', <GoldOutlined />, [
            getItem(t('navigation.asset.assetBook'), 'asset.assetBook'),
            getItem(t('navigation.asset.assetType'), 'asset.assetType')
        ]),*/
        getItem(t('navigation.report.name'), 'report', <BarChartOutlined />, [
            getItem(t('navigation.report.sale'), 'report.sale'),
            getItem(t('navigation.report.inventory'), 'report.inventory'),
            getItem(t('navigation.report.finance'), 'report.finance'),
            getItem(t('navigation.report.debt'), 'report.debt'),
        ]),
        getItem(t('navigation.system.name'), 'system', <SettingOutlined />, [
            getItem(t('navigation.system.company'), 'system.company'),
            getItem(t('navigation.system.companySubscribePlan'), 'system.company-subscribe-plan'),
            //getItem(t('navigation.system.users'), 'system.users'),
            getItem(t('navigation.system.role'), 'system.role'),
            getItem(t('navigation.system.printTemplate'), 'system.printTemplate'),
            getItem(t('navigation.system.closeBook'), 'system.closeBook'),
            //getItem(t('navigation.system.languages'), 'system.languages'),
            //getItem(t('navigation.system.logs'), 'system.logs'),
            //getItem(t('navigation.system.backupRestore'), 'system.backupRestore'),
            getItem(t('navigation.system.setting'), 'system.setting'),
        ])
    ];

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onClick = (e) => {
        switch (e.key){
            case "dashboard":
                props.history.push('/')
                break
            case "sale.pos":
                props.history.push('/pos')
                break
            case "sale.invoice":
                props.history.push('/pos/sales')
                break
            case "sale.area":
                props.history.push('/pos/areas')
                break
            case "sale.table":
                props.history.push('/pos/tables')
                break
            case "sale.counter":
                props.history.push('/pos')
                break
            case "sale.priceBook":
                props.history.push('/pos/price_books')
                break
            case "inventory.stock":
                props.history.push('/stocks')
                break
            case "inventory.audit":
                props.history.push('/inventory/audits')
                break
            case "inventory.product":
                props.history.push('/inventory/products')
                break
            case "inventory.productCategory":
                props.history.push('/inventory/product_categories')
                break
            case "inventory.unit":
                props.history.push('/inventory/units')
                break
            case "inventory.warehouse":
                props.history.push('/inventory/warehouses')
                break
            case "inventory.brand":
                props.history.push('/inventory/brands')
                break
            case "inventory.attribute":
                props.history.push('/inventory/attributes')
                break
            case "inventory.barcode":
                props.history.push('/inventory/barcode')
                break
            case "inventory.shelves":
                props.history.push('/inventory/shelves')
                break
            case "finance.cashBook":
                props.history.push('/finance/cashbook')
                break
            case "finance.cashBookMoney":
                props.history.push('/finance/cash_book_money')
                break
            case "finance.cashBookBank":
                props.history.push('/finance/bankbook')
                break
            case "finance.cashier":
                props.history.push('/finance/cashier')
                break
            case "finance.receiptsType":
                props.history.push('/finance/receipts_type')
                break
            case "finance.paymentsType":
                props.history.push('/finance/payments_type')
                break
            case "finance.coffer":
                props.history.push('/finance/coffer')
                break
            case "finance.fund":
                props.history.push('/finance/fund')
                break
            case "marketing.campaign":
                props.history.push('/marketing/campaign')
                break
            case "marketing.voucher":
                props.history.push('/marketing/voucher')
                break
            case "marketing.coupon":
                props.history.push('/marketing/coupon')
                break
            case "marketing.point":
                props.history.push('/marketing/point/transaction')
                break
            case "marketing.accumulation":
                props.history.push('/marketing/accumulation')
                break
            case "hrm.staff":
                props.history.push('/hrm/staffs')
                break
            case "hrm.position":
                props.history.push('/hrm/positions')
                break
            case "hrm.division":
                props.history.push('/hrm/divisions')
                break
            case "hrm.workTypes":
                props.history.push('/hrm/worktypes')
                break
            case "hrm.workHours":
                props.history.push('/hrm/whs')
                break
            case "hrm.timeTracking":
                props.history.push('/hrm/timetrackings')
                break
            case "hrm.leave":
                props.history.push('/hrm/leave')
                break
            case "hrm.leaveType":
                props.history.push('/hrm/leave_type')
                break
            case "hrm.allowances":
                props.history.push('/hrm/allowances')
                break
            case "hrm.deductions":
                props.history.push('/hrm/deductions')
                break
            case "hrm.violationPenaltyType":
                props.history.push('/hrm/violation_penalty_type')
                break
            case "hrm.salaryConfig":
                props.history.push('/hrm/salary/config')
                break
            case "crm.customer":
                props.history.push('/crm/customers')
                break
            case "crm.supplier":
                props.history.push('/crm/suppliers')
                break
            case "crm.customerGroup":
                props.history.push('/crm/customergroups')
                break
            case "crm.supplierGroup":
                props.history.push('/crm/suppliergroups')
                break
            case "debt":
                props.history.push('/debts')
                break
            case "asset.assetBook":
                props.history.push('/asset/book')
                break
            case "asset.assetType":
                props.history.push('/asset/type')
                break
            case "report.sale":
                props.history.push('/report/sale')
                break
            case "report.sale.general":
                props.history.push('/report/sale/general')
                break
            case "report.sale.customer":
                props.history.push('/report/sale/customer')
                break
            case "report.sale.product":
                props.history.push('/report/sale/product')
                break
            case "report.sale.category":
                props.history.push('/report/sale/category_product')
                break
            case "report.sale.staff":
                props.history.push('/report/sale/staff')
                break
            case "report.sale.branch":
                props.history.push('/report/sale/branch')
                break
            case "report.sale.brand":
                props.history.push('/report/sale/brand')
                break
            case "report.sale.area":
                props.history.push('/report/sale/area')
                break
            case "report.sale.table":
                props.history.push('/report/sale/table')
                break
            case "report.sale.supplier":
                props.history.push('/report/sale/product_supplier')
                break
            case "report.inventory":
                props.history.push('/report/inventory')
                break
            case "report.inventory.dashboard":
                props.history.push('/report/inventory/dashboard')
                break
            case "report.inventory.branch":
                props.history.push('/report/inventory/branch')
                break
            case "report.inventory.supplier":
                props.history.push('/report/inventory/supplier')
                break
            case "report.inventory.product":
                props.history.push('/report/inventory/product')
                break
            case "report.finance":
                props.history.push('/report/finance')
                break
            case "report.finance.cash":
                props.history.push('/report/finance/cashbook/cash')
                break
            case "report.finance.bank":
                props.history.push('/report/finance/cashbook/bank')
                break
            case "report.finance.cashier":
                props.history.push('/report/finance/cashier')
                break
            case "report.debt":
                props.history.push('/report/debts')
                break
            case "system.company":
                props.history.push('/system/company/info')
                break
            case "system.company-subscribe-plan":
                props.history.push('/system/company/subscribe-plan')
                break
            case "system.users":
                props.history.push('/system/users')
                break
            case "system.role":
                props.history.push('/system/roles')
                break
            case "system.printTemplate":
                props.history.push('/system/print_template')
                break
            case "system.closeBook":
                props.history.push('/system/close_books')
                break
            case "system.languages":
                props.history.push('/system/languages')
                break
            case "system.logs":
                props.history.push('/system/logentries')
                break
            case "system.backupRestore":
                props.history.push('/system/backup')
                break
            case "system.setting":
                props.history.push('/system/setting/config')
                break
        }
    }

    const updateWindowDimensions = () => {
        //setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        }
    }, [])

    return(
        <Sider style={{ background: "white" }}
               trigger={null} collapsible
               collapsed={props.configs.UI_HOME_MENU_MODE === 'ICON'}
               width={260}
        >
            <Affix offsetTop={65}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['dashboard']}
                    defaultOpenKeys={['dashboard']}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{
                        height: `${height-64}px`,
                        borderRight: 0,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    items={items}
                    onClick={onClick}
                    collapsed={`${props.configs.UI_HOME_MENU_MODE === 'ICON'}`}
                />
            </Affix>
        </Sider>
    )
}

LeftSidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    user: PropTypes.object,
    configs: PropTypes.object
};

const mapStateToProps = state => ({
    settings: state.settings,
    configs: state.configs,
    user: state.user,
});
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LeftSidebar));