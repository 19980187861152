import gpl from "graphql-tag";
import {areaFieldDefault, areaFieldSimple} from "../fragments/areaFragment";

// Lấy thông tin chi tiết khu vực bán hàng theo chi nhánh
export const AREA_DETAIL_BY_BRANCH_QUERY = gpl`
    ${areaFieldDefault}
    query areaDetailByBranch($branchId: Int, $areaId: Int){
    areaDetailByBranch(branchId: $branchId, areaId: $areaId){
        ...areaFieldDefault
    }
}
`;

// Lấy danh sách khu vực bán hàng (phân trang)
export const AREAS_BY_BRANCH_PAGINATE_QUERY = gpl`
    ${areaFieldDefault}
    query areasByBranchPaginate(
        $branchId: Int, $keyword: String, $sort: String, $order: String, $enable: String, $show: Int, $page: Int
    ){
    areasByBranchPaginate(branchId: $branchId, keyword: $keyword, sort: $sort, order: $order, enable: $enable, show: $show, page: $page){
        page
        pages
        numberRecords
        hasNext
        hasPrev
        objects{
            ...areaFieldDefault
        }
    }
}`;

// Lấy danh sách tất cả khu vực bán hàng theo chi nhánh
export const AREAS_BY_BRANCH_QUERY = gpl`
    ${areaFieldSimple}
    query areasByBranch($branchId: Int){
        areasByBranch(branchId: $branchId){
            ...areaFieldSimple
        }
    }
`;
