import {CHANGE_CONFIG} from '../actions/actions';

const configs = store => next => action => {
    let result = next(action);
    if (action.type === CHANGE_CONFIG) {
        return (store.getState())
    }
    return result
};

export default configs;
