import settings from './settings.middleware.js'
import configs from './configs.middleware.js';
import themes from './themes.middleware.js';
import user from './user.middleware.js';
import menus from './menus.middleware.js';
import tours from './tours.middleware.js';

export default [
    settings,
    configs,
    themes,
    user,
    menus,
    tours
]
