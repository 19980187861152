import gpl from "graphql-tag";


const BRANCH_DETAIL_QUERY = gpl`
    query branchDetail($branchId: Int){
        branchDetail(branchId: $branchId){
            branchId
            branchCode
            branchName
            branchAvatar
            branchPhone
            branchEmail
            branchMobile
            branchFax
            branchAddress
            branchDescription
            branchEnable
            branchOfCompany{
                companyId
                companyName
                companyCode
                companyAddress
                companyPhone
            }
            branchTypeOfBusiness{
                tobId
            }
            branchManager{
                staffId
            }
            staffsSet{
                staffId
                staffCode
                staffName
                staffAvatar
                staffDivision{
                    divisionId
                    divisionName
                }
            }
            warehouseBranch{
                warehouseId
                warehouseCode
                warehouseName
            }
        }
    }
`;


// Lấy danh sách chi nhánh
const BRANCHES_QUERY = gpl`
    query branchesList(
        $keyword: String
    ){
        branchesList(
            keyword: $keyword
        ){
            branchId
            branchCode
            branchName
            branchAvatar
            branchAddress
            branchEmail
            branchMobile
            branchStatus
            branchEnable
            branchOfCompany{
                companyId
                companyOwner{
                    staffId
                }
            }
        }
    }
`;


// Lấy danh sách chi nhánh khả dụng theo người dùng đăng nhập
const BRANCHES_FOR_STAFF_QUERY = gpl`
    query branchesForStaff(
        $keyword: String
    ){
        branchesForStaff(
            keyword: $keyword
        ){
            branchId
            branchCode
            branchName
            branchAvatar
            branchAddress
            branchEmail
            branchMobile
            branchStatus
            branchEnable
            branchManager{
              staffId
            }
            branchOfCompany{
                companyId
                companyOwner{
                    staffId
                }
            }
            branchTypeOfBusiness{
                tobId
                tobCode
                tobName
            }
        }
    }
`;

// kiểm tra chủ cửa hàng dựa theo Id nhân viên
const CHECK_STAFF_STORE_OWNER = gpl`
    query checkStaffStoreOwner($staffId:Int){
        checkStaffStoreOwner(staffId:$staffId)
    }
`;

// danh sách chi nhánh dựa theo Id cửa hàng
const BRANCHES_LIST_BASED_ON_COMPANY_QUERY = gpl`
    query branchesListBasedOnCompany($companyId: Int){
        branchesListBasedOnCompany(companyId:$companyId){
            branchId
            branchName
        }
    }
`;

// danh sách chi nhánh
const BRANCHES_PAGINATE_QUERY = gpl`query branchesPaginate(
    $keyword: String
    $sort: String
    $order: String
    $enable: String
    $show: Int
    $page: Int
){
    branchesPaginate(
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ) {
        page
        pages
        numberRecords
        hasNext
        hasPrev
        keyword
        objects{
            branchId
            branchCode
            branchName
            branchAvatar
            branchEnable
            branchCreatedAt
            branchManager{
                staffId
                staffCode
                staffName
                staffAvatar
                staffEnable
                staffStatus
            }
            staffsSet{
                staffId
                staffCode
                staffAvatar
                staffName
                staffEnable
                staffStatus
            }
            branchUserCreated{
                username
            }
        }
    }
}`;

// tạo mã chi nhánh tự động
const AUTO_GENERATE_BRANCH_CODE = gpl`
    {
        autoGenerateBranchCode
    }
`;

export {
    BRANCH_DETAIL_QUERY,
    BRANCHES_QUERY,
    CHECK_STAFF_STORE_OWNER,
    BRANCHES_FOR_STAFF_QUERY,
    BRANCHES_LIST_BASED_ON_COMPANY_QUERY,
    BRANCHES_PAGINATE_QUERY,
    AUTO_GENERATE_BRANCH_CODE
}
