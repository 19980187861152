import React, {useEffect, useState} from "react";
import {Alert, Button, Modal} from 'antd';
import {useQuery} from "@apollo/client";
import {COMPANY_VERIFY_SUBSCRIBE_PLAN} from "../../api/company_subscribe_plan/companySubscribePlanQuery";
import moment from "moment";
import ActiveKey from "../Organisms/Form/SubscribePlan/ActiveKey";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../store/actions/actions";
import {connect} from "react-redux";
import {withRouter, useHistory} from "react-router-dom";


const VerifySubscribePlan = (props) => {
    const history = useHistory()

    const [modalActive, setModalActive] = useState(false);

    const {loading, data} = useQuery(COMPANY_VERIFY_SUBSCRIBE_PLAN)

    const toggleModalActive = () => {
        setModalActive(!modalActive);
    };

    useEffect(() => {
        if(!props.user.isSubscribePlanExpired){
            if(history.location.pathname !== '/expired') {
                props.history.push('/expired')
            }
        }

        if(data && data.companyVerify){
            if(moment(data.companyVerify.expiryDate) < moment() || data.companyVerify.enabled === false) {
                props.actions.changeUserLogin("isSubscribePlanExpired", false);
                if(history.location.pathname !== '/expired') {
                    props.history.push('/expired')
                }
            }
        }

    }, [data, history.location.pathname])

    return(
        <>
            {(!loading || true) &&
                (data && data.companyVerify && ['-1', '-2', '-3'].includes(moment().diff(data.companyVerify.expiryDate, 'days').toString())) &&
                    <Alert message={`Gói dịch vụ của bạn sẽ hết hạn vào ngày ${moment(data.companyVerify.expiryDate).format('HH:mm DD/MM/YYYY')}, hãy gia hạn để không bị gián đoạn trong quá trình sử dụng.`}
                           banner
                           action={
                               <Button size="small" type="primary" onClick={toggleModalActive}>
                                   Gia hạn
                               </Button>
                           }
                           closable
                    />
            }

            <Modal open={modalActive}
                   onCancel={toggleModalActive}
                   footer={null}
                   width={600}
            >
                <ActiveKey onError={() => {}}
                           onCompleted={() => {
                               toggleModalActive();
                           }}
                           onGoHome={toggleModalActive}
                           onCancel={toggleModalActive}
                           onDenied={() => {}}/>
            </Modal>
        </>
    )
}

VerifySubscribePlan.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};

const mapStateToProps = state => ({ settings: state.settings, configs: state.configs, user: state.user,  menus: state.menus });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VerifySubscribePlan));