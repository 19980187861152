import gpl from "graphql-tag";
import {customerFieldDefault, customerFieldDetail, customerFieldSimple} from "../fragments/customerFragment";

// thông tin cho dashboard
export const NEW_CUSTOMER_MONTH = gpl`
   query newCustomerMonth($fromDate:String,$toDate:String) {
        newCustomerMonth(fromDate:$fromDate,toDate:$toDate){
           customerCount
           customerCreatedDate
        }
   }
`;

// thống kê số lượng khách hàng
export const CUSTOMER_COUNT = gpl`
    query {
        customerCount
    }
`;

// danh sách khách hàng// $customerLevel: String
//     // $customerGroups: [Int] // customerLevel: $customerLevel
//         // customerGroups: $customerGroups
export const CUSTOMERS_PAGINATE_QUERY = gpl`
    ${customerFieldDefault}
    query customersPaginate(
    $groups: [Int]
    $level: [Int]
    $keyword: String
    $sort: String
    $order: String
    $enable: String
    $show: Int
    $page: Int
){
    customersPaginate(
        groups: $groups
        level: $level
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ) {
        page
        pages
        numberRecords
        hasNext
        hasPrev
        keyword
        objects{
            ...customerFieldDefault
        }
    }
}`;

// xuất danh sách khách hàng
export const CUSTOMERS_EXPORT_QUERY = gpl`query customerExport{
        customerExport{
            customerAvatar
            customerVipCode
            customerCode
            customerName
            customerPhone
            customerEmail
            customerMobile
            customerType
            customerCompanyName
            customerPosition
            customerTax
            customerGroups{
                cusGroupId
                cusGroupName
            }
            customerLevel{
                customerLevelId
                customerLevelName
            }
            customerBank{
                bankId
                bankCode
                bankNameVn
            }
            customerBankNum
            customerAddress
            customerVillage{
                wardsId
                wardsName
            }
            customerDistrict{
                districtId
                districtName
            }
             customerProvince{
                provinceId
                provinceName
            }
            customerWebsite
            customerDescription
            customerEnable   
            customerCreatedAt
            customerUserCreated{
                username
            }         
        }
    }
`;

// Lấy danh sách khách hàng
export const CUSTOMERS_FOR_POS_QUERY = gpl`
    ${customerFieldSimple}
    query customersForPos(
        $keyword: String
    ){
        customersForPos(
            keyword: $keyword
        ){
            ...customerFieldSimple
        }
    }
`;

//Lấy danh sách khách hàng
export const CUSTOMERS_AVAILABLE_QUERY = gpl`
    ${customerFieldSimple}
    query availableCustomers{
        availableCustomers{
            ...customerFieldSimple
        }
    }
`;

// Tạo hàng hóa tạm/lấy hàng hóa đang thao tác thêm mới của nhân viên
export const CUSTOMER_TEMP_QUERY = gpl`
    ${customerFieldDefault}
   query customerDetail($customerId:Int)
    {
        customerDetail(customerId:$customerId){
            ...customerFieldDefault
        }
    }
`;

// chi tiết khách hàng
export const CUSTOMER_DETAIL = gpl`
    ${customerFieldDetail}
    query customerDetail($customerId: Int){
        customerDetail(customerId: $customerId){
            ...customerFieldDetail
        }
    }
`;
