import gpl from "graphql-tag";
import {subscribePlanFieldDefault} from "./subscribePlanFragment";

export const companySubscribePlanFieldDefault = gpl`
  ${subscribePlanFieldDefault}
  fragment companySubscribePlanFieldDefault on CompanySubscribePlanNode{
    id
    subscribePlan{
        ...subscribePlanFieldDefault
    }
    registrationDate
    expiryDate
    features{
      code
      name
    }
    maxUser
    maxBranch
    maxWarehouse
    enabled
  }
`;
