import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {Button, CardBody, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import * as actions from "../../../../store/actions/actions";
import AccessDenied from "../../../Pages/System/Pages/AccessDenied";
import _ from "lodash";
import {CASHIER_CREATE_MUTATION} from "../../../../api/cashier/cashierMutation";
import SelectCoffer from "../../../Atoms/Select/SelectCoffer";
import ReactNumeric from "react-numeric";
import moment from "moment";

let CurrencyFormat = require('react-currency-format');

const CashierCheck = (props) => {
    let branchActivate = props.user.branchActivate ? parseInt(props.user.branchActivate.branchId) : 0;

    let isManager = false;
    let staffLogin = null;

    if(props.user){
        staffLogin = props.user.staff.staffId;

        if(props.user.isSuperuser){
            isManager = true;
        }else if(props.user.branchActivate){
            let branchManager = props.user.branchActivate.branchManager;
            let staffManagerBranch =  branchManager.staffId;
            if(staffLogin === staffManagerBranch){
                isManager = true;
            }
        }else if(props.user.branchActivate.branchOfCompany){
            let branchOfCompany = props.user.branchActivate.branchOfCompany;
            let staffManagerCompany = branchOfCompany.companyOwner;
            if(staffManagerCompany.staffId === staffLogin){
                isManager = true;
            }
        }
    }

    let toDay = moment().format("YYYY-MM-DDTHH:mm");

    const [permission] = useState({
        //Thêm mới
        add: props.user.permissions.includes("add_cashier"),
        //Cập nhật
        change: props.user.permissions.includes("change_cashier"),
        //Xóa
        delete: props.user.permissions.includes("delete_cashier"),
        //Xem danh sách
        view: props.user.permissions.includes("view_cashier"),
        //Xem chi tiết
        detail: props.user.permissions.includes("detail_cashier"),
        isManager: isManager,
    });
    const [formCreate, setFormCreate] = useState({
        cashierBranchId: (props.initData && props.initData.cashierBranch) ? props.initData.cashierBranch.branchId : branchActivate,
        cashierDate: (props.initData && props.initData.cashierDate) ? props.initData.cashierDate : toDay,
        cashierType: (props.initData && props.initData.cashierType) ? props.initData.cashierType : null,
        cashierValue: (props.initData && props.initData.cashierValue) ? props.initData.cashierValue : 0,
        cashierNote:  (props.initData && props.initData.cashierNote) ? props.initData.cashierNote : "",
        cashierCofferId: (props.initData && props.initData.cashierCoffer) ? props.initData.cashierCoffer.cofferId : null,
        cashierStaffId: (props.initData && props.initData.cashierStaff) ? props.initData.cashierStaff.staffId : staffLogin,
    });
    const [thousandSeparator] = useState(props.configs.UI_THOUSAND_SEPARATOR); // Phân cách đơn vị hàng nghìn
    const [decimalSeparator] = useState(props.configs.UI_DECIMAL_SEPARATOR); // Phân cách thập phân
    //const [decimalScale] = useState(props.configs.UI_DECIMAL_SCALE); // Chỉ số thập phân được lấy
    //const [wheelStepQuantity] = useState(1);
    const [wheelStepCurrency] = useState(1000);
    const [toastOption] = useState({
        toastId: 'cashierCheck',
        containerId: 'cashierCheck',
        type: 'warning',
        position: 'top-left'
    });
    const [parValue, setParValue] = useState([
        { name: 500000, qty: 0},
        { name: 200000, qty: 0},
        { name: 100000, qty: 0},
        { name: 50000, qty: 0},
        { name: 20000, qty: 0},
        { name: 10000, qty: 0},
        { name: 5000, qty: 0},
        { name: 2000, qty: 0},
        { name: 1000, qty: 0},
        { name: 500, qty: 0},
    ])
    let money = [500000, 200000, 100000, 50000, 20000, 10000, 5000, 2000, 1000, 500]

    const onChange = (event) => {
        let input = event.target;
        let value = input.type === 'checkbox' ? input.checked : input.value;

        setFormCreate({
            ...formCreate,
            [input.name]: value,
        })
    };

    const handleSave = (cashierType) => {
        let errors = {};

        let rsCashierCoffer = null;
        if(_.isNil(formCreate.cashierCofferId)){
            rsCashierCoffer = "Vui lòng chọn két tiền"
        }
        errors = { ...errors, ['cashierCofferId']: rsCashierCoffer};

        let rsCashierValue = null;
        if(_.isNil(formCreate.cashierValue)){
            rsCashierValue = "Vui lòng nhập số tiền"
        }else if(parseFloat(formCreate.cashierValue) < 0){
            rsCashierValue = "Số tiền phải lớn hơn hoặc bằng không"
        }
        errors = { ...errors, ['cashierValue']: rsCashierValue};

        setFormCreate({
            ...formCreate,
            errors
        });

        let hasError = !!(errors['cashierValue'] || errors['cashierCofferId']);

        if (!hasError) {
            createCashier({
                variables: {
                    cashierArgs: {
                        cashierBranchId: formCreate.cashierBranchId,
                        cashierCofferId: formCreate.cashierCofferId,
                        cashierStaffId: formCreate.cashierStaffId,
                        cashierDate: formCreate.cashierDate,
                        cashierType: cashierType,
                        cashierValue: parseFloat(formCreate.cashierValue),
                        cashierNote: formCreate.cashierNote,
                        cashierParValues: JSON.stringify(parValue)
                    }
                }
            }).then(result => {
                if (result && result.data){
                    props.onCompleted(result.data)
                }
            });
        }
    };

    const onCancel = () => {
        props.onCancel()
    };

    const handleDenied = () => {
        if(props.onDenied()){
            props.onDenied()
        }
    }

    const [createCashier, {loading: loadingCashier}] = useMutation(CASHIER_CREATE_MUTATION);

    useEffect(() => {

        return () => {
        }
    });

    return (
        <>
            <Helmet>
                <title>Két tiền - {formCreate.cashierType === "checkin" ? "Mở ca" : formCreate.cashierType === "checkout" ? "Đóng ca" : ""} | {process.env.REACT_APP_NAME}</title>
            </Helmet>

            {
                permission.add ?
                    <div className={props.headerFixed ? "card card-default card-fixed m-0" : "card card-default m-0"}>
                        <div className={props.headerFixed ? "card-header header-fixed" : "card-header"}>
                            <div className="nav-sub">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <h4 className="font-weight-normal">
                                                <label className="text-primary cursor-pointer mb-0">Mở / Đóng ca</label>
                                            </h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 pl-0">
                                                <button
                                                    title="Mở ca"
                                                    id="btnCheckIn"
                                                    className="btn btn-sm btn-primary font-weight-bold text-white mr-1"
                                                    onClick={() => handleSave("checkin")}
                                                    disabled={loadingCashier}
                                                >
                                                    <i className={loadingCashier ? "fas fa-circle-notch fa-spin" : "fas fa-door-open"}/> Mở ca
                                                </button>

                                                <button
                                                    title="Đóng ca"
                                                    id="btnCheckOut"
                                                    className="btn btn-sm btn-success font-weight-bold text-white"
                                                    onClick={() => handleSave("checkout")}
                                                    disabled={loadingCashier}
                                                >
                                                    <i className={loadingCashier ? "fas fa-circle-notch fa-spin" : "fas fa-door-closed"}/> Đóng ca
                                                </button>

                                                <div id="btnBack"
                                                     onClick={onCancel}
                                                     className="ml-1 btn btn-sm btn btn-outline-primary font-weight-bold"
                                                >
                                                    <i className="fas fa-times"/> Hủy
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="m-1"/>

                        <CardBody>
                            <div className="row">
                                <div className={props.fullWidth ? "col-12" : "col-12 col-xl-6 col-lg-8 col-md-10"}>

                                    <form id="formCreate" name="formCreate">

                                        <div className="row">
                                            <div className="col-6">
                                                <label>
                                                    Mệnh giá
                                                    <sup className="text-danger font-weight-bold"> *</sup>
                                                </label>

                                                {money.map(item =>
                                                    <FormGroup row>
                                                        <label className="col-md-4 col-form-label">
                                                            <CurrencyFormat
                                                                value={item}
                                                                displayType={'text'}
                                                                thousandSeparator={thousandSeparator}
                                                                decimalSeparator={decimalSeparator}
                                                                suffix={'đ'}
                                                            />
                                                        </label>
                                                        <div className="col-md-8">
                                                            <InputGroup>
                                                                <ReactNumeric className="form-control form-control-sm"
                                                                              style={{height: '35px'}}
                                                                              value={parValue.filter(item => item.name === item)[0]?.qty}
                                                                              onChange={(event, value) => {
                                                                                  let newRecords = parValue
                                                                                  let objIndex = newRecords.findIndex((obj => obj.name === item));
                                                                                  newRecords[objIndex].qty = value
                                                                                  setParValue(newRecords)

                                                                                  const sum = newRecords.reduce((accumulator, object) => {
                                                                                      return accumulator + (object.name * object.qty);
                                                                                  }, 0);
                                                                                  setFormCreate({
                                                                                      ...formCreate,
                                                                                      cashierValue: sum
                                                                                  })
                                                                              }}
                                                                              decimalPlaces={0}
                                                                              wheelStep={1}
                                                                              minimumValue="0"
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>Tờ</InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                    </FormGroup>
                                                )}
                                            </div>
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label>
                                                        Két tiền
                                                        <sup className="text-danger font-weight-bold"> *</sup>
                                                    </label>
                                                    <SelectCoffer
                                                        cofferId={formCreate.cashierCofferId}
                                                        onChange={(cofferId) => {
                                                            setFormCreate({
                                                                ...formCreate,
                                                                cashierCofferId: cofferId
                                                            })
                                                        }}
                                                    />
                                                    {formCreate.errors && <span className="invalid-message">{formCreate.errors['cashierCofferId']}</span>}
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>
                                                        Số tiền
                                                        <sup className="text-danger font-weight-bold"> *</sup>
                                                    </label>
                                                    <ReactNumeric
                                                        className="form-control col-12"
                                                        value={formCreate.cashierValue}
                                                        currencySymbol=""
                                                        decimalPlaces={2}
                                                        wheelStep={wheelStepCurrency}
                                                        minimumValue="0"
                                                        overrideMinMaxLimits={"ignore"}
                                                        digitGroupSeparator={thousandSeparator}
                                                        decimalCharacter={decimalSeparator}
                                                        onChange={(event, value) => {
                                                            setFormCreate({
                                                                ...formCreate,
                                                                cashierValue: value,
                                                            });
                                                        }}
                                                        readOnly disabled
                                                    />
                                                    {formCreate.errors && <span className="invalid-message">{formCreate.errors['cashierValue']}</span>}
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>
                                                        Mô tả
                                                    </label>
                                                    <textarea className="form-control mousetrap" placeholder="Mô tả"
                                                              maxLength="500"
                                                              name="cashierNote"
                                                              onChange={onChange}
                                                              value={formCreate.cashierNote}
                                                              rows={5}
                                                    />

                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </CardBody>
                    </div>
                    : <AccessDenied agree={handleDenied}/>
            }

            <ToastContainer containerId={toastOption.containerId} enableMultiContainer={true} limit={1}/>
        </>
    )
};

CashierCheck.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};

const mapStateToProps = state => ({settings: state.settings, configs: state.configs, user: state.user, menus: state.menus});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CashierCheck));
