import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import Shortcut from "../../../../Shortcut";
import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import * as actions from "../../../../store/actions/actions";
import SkeletonForm from "../../../Molecules/Skeleton/SkeletonForm";
import {COFFER_DETAIL_QUERY} from "../../../../api/coffers/cofferQuery";
import {COFFER_CREATE_MUTATION, COFFER_UPDATE_MUTATION} from "../../../../api/coffers/cofferMutation";
import {Checkbox, Col, Divider, Form, Input, Row} from "antd";
import HeaderAction from "../../../Molecules/HeaderAction/HeaderAction";
import {HomeOutlined} from "@ant-design/icons";

const Mousetrap = require("mousetrap");
const { TextArea } = Input;

const CofferForm = (props) => {
    const [form] = Form.useForm();
    const [saveContinue, setSaveContinue] = useState(false)

    const onLoadData = (result) => {
        let data = result.data;

        if(data && data.coffer){
            let coffer = data.coffer;

            form.setFields([
                {name: "cofferId", value: coffer.cofferId},
                {name: "cofferName", value: coffer.cofferName},
                {name: "cofferNote", value: coffer.cofferNote},
                {name: "cofferEnable", value: coffer.cofferEnable},
            ])
        }
    };

    const onSave = (values) => {
        let cofferArgs = {
            cofferId: props.id ? parseInt(props.id) : null,
            cofferName: values.cofferName,
            cofferBranchId: props.user.branchActivate ? parseInt(props.user.branchActivate.branchId) : 0,
            cofferNote: values.cofferNote,
            cofferEnable: props.id ? values.cofferEnable : true
        }
        if(props.id) {
            updateCoffer({
                variables: cofferArgs
            }).then(result => {
                if (result && result.data) {
                    props.onCompleted(result.data, saveContinue)
                }
            });
        }else {
            createCoffer({
                variables: cofferArgs
            }).then(result => {
                if (result && result.data) {
                    props.onCompleted(result.data, saveContinue)
                }
            });
        }
    };

    const onGoHome = () => {
        props.onGoHome()
    };

    const onCancel = () => {
        props.onCancel()
    };

    const onError = (error) => {
        if (props.onError()) {
            props.onError(error)
        }
    };

    const [getCoffer, { loading }] = useLazyQuery(COFFER_DETAIL_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache"
    });

    const [createCoffer, {loading: loadingCreate}] = useMutation(COFFER_CREATE_MUTATION);

    const [updateCoffer, {loading: loadingUpdate}] = useMutation(COFFER_UPDATE_MUTATION);

    useEffect(() => {
        if(props.id){
            getCoffer({variables: { cofferId: props.id} }).then(onLoadData).catch(onError)
        }

        Mousetrap.bind(Shortcut.filter(key => key.code === "SAVE")[0].key, (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
            document.getElementById("btnSave").click();
        });
        Mousetrap.bind(Shortcut.filter(key => key.code === "SAVE_AND_CONTINUE")[0].key, (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
            document.getElementById("btnSaveAndContinue").click();
        });
        Mousetrap.bind(Shortcut.filter(key => key.code === "BACK")[0].key, (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
            document.getElementById("btnBack").click();
        });

        return () => {
            Mousetrap.unbind(Shortcut.filter(key => key.code === "SAVE")[0].key);
            Mousetrap.unbind(Shortcut.filter(key => key.code === "SAVE_AND_CONTINUE")[0].key);
            Mousetrap.unbind(Shortcut.filter(key => key.code === "BACK")[0].key);
        }
    }, [props.id]);

    return (
        <>
            <Helmet>
                <title>Két giữ tiền | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <HeaderAction title={"Két giữ tiền"}
                          breadcrumb={[
                              {
                                  href: '/',
                                  title: <HomeOutlined />,
                              },
                              {
                                  title: <label onClick={onGoHome}>Két giữ tiền</label>,
                              },
                              {
                                  title: props.id ? (props.onlyView ? 'Xem': 'Sửa'): 'Tạo',
                              },
                          ]}
                          onSave={() => {
                              setSaveContinue(false);
                              form.submit();
                          }}
                          onSaveContinue={() => {
                              setSaveContinue(true);
                              form.submit();
                          }}
                          onChange={(e)=>{
                              e.preventDefault();
                              props.history.replace('/finance/coffer/update/' + props.id)
                          }}
                          onCancel={onCancel}
                          loadingUpdate={loadingUpdate}
                          loadingCreate={loadingCreate}
                          loadingData={loading}
                          id={props.id}
                          onlyView={props.onlyView}
                          headerFixed={props.headerFixed}
            />

            <Divider />

            {(loading || loadingCreate || loadingUpdate) ? <SkeletonForm rows={4}/> :
                <Form form={form}
                      onFinish={onSave}
                      layout={"vertical"}
                      disabled={props.onlyView ? props.onlyView : false}
                >
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Form.Item
                                name="cofferName"
                                label="Tên két"
                                rules={[
                                    {
                                        required: true, message: 'Vui lòng nhập tên két'
                                    },
                                ]}
                            >
                                <Input name="cofferName" id="cofferName" placeholder="Tên két"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item name="cofferNote" label="Mô tả" >
                        <TextArea rows={4}
                                  name="cofferNote"
                                  placeholder="Mô tả"
                        />
                    </Form.Item>

                    {props.id &&
                        <Form.Item name="cofferEnable" valuePropName="checked">
                            <Checkbox name="cofferEnable">
                                Còn quản lý
                            </Checkbox>
                        </Form.Item>
                    }
                </Form>
            }
        </>
    )
};

CofferForm.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};

const mapStateToProps = state => ({settings: state.settings, configs: state.configs, user: state.user, menus: state.menus});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CofferForm));
