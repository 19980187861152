import React from 'react';

const PageLoader = () => (
    <div className="page-loader">
        <em className="fas fa-circle-notch fa-spin fa-2x text-primary"/>
        <br/>
        Đang tải trang...
    </div>
);

export default PageLoader;
