import React from "react";

const DataEmpty = ({icon, text, className, iconClick}) => {
    return(
        <div className={className ? className : "text-center p-5"}>
            {icon &&
                <>
                    <em className={icon.length > 0 ? icon : "fa-2x icon-magnifier"} onClick={iconClick}/>
                    <br/>
                </>
            }
            <i>{text ? text : "Dữ liệu trống hoặc không tìm thấy"}</i>
        </div>
    )
};

export default DataEmpty
