import gpl from "graphql-tag";
import {userFieldDefault} from "./userFragment";

export const printFormFieldDefault = gpl`
  ${userFieldDefault}
  fragment printFormFieldDefault on PrintFormNode{
    printFormId
    printFormType
    printFormName
    printFormContent
    printFormDefault
    printFormUserCreated{
        ...userFieldDefault
    }
    printFormCreatedAt
  }
`;
