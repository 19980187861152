import styled from 'styled-components';

const Wrapper = styled.div`
  .ant-layout-header{
    padding: 0px;
    height: 64px;
    line-height: 64px;
    width: 100%;
    z-index: 19;
  }
  
  .ant-layout-footer, .ant-layout-header {
    flex: 0 0 auto;
  }
  
  .ant-pro-basicLayout .ant-layout-header.ant-pro-header-light {
    background: #fff;
  }

  .ant-pro-fixed-header-action {
    transition: width .3s cubic-bezier(.645,.045,.355,1);
  }
  
  .ant-pro-top-nav-header.light {
    background-color: #fff;
  }

  .ant-pro-top-nav-header {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 4px 0 rgba(0,21,41,.12);
    transition: background .3s,width .2s;
  }

  .ant-pro-top-nav-header-main {
    display: flex;
    height: 100%;
    padding-left: 16px;
  }

  .header-min{
    .ant-pro-top-nav-header-main-left {
      min-width: 48px !important;
    }

    .ant-pro-top-nav-header-logo{
      min-width: 48px !important;;
    }
  }

  .ant-pro-top-nav-header-main-left {
    display: flex;
    min-width: 192px;
  }

  .ant-pro-top-nav-header-menu {
    min-width: 0;
    flex: 1 1 0%;
  }
  
  .ant-pro-right-content{
    min-width: 224px;
  }

  .ant-pro-top-nav-header-logo {
    position: relative;
    min-width: 165px;
    height: 100%;
    overflow: hidden;
    text-align: center;
  }

  .ant-pro-top-nav-header-logo a>svg, .ant-pro-top-nav-header-logo img {
    display: inline-block;
    height: 45px;
    vertical-align: middle;
  }

  .ant-layout-header.ant-pro-fixed-header {
    position: fixed;
    top: 0;
    left: 0;
  }

  .ant-pro-fixed-header-action {
    transition: width .3s cubic-bezier(.645,.045,.355,1);
  }
  
  .notification, .profile{
    padding-left: 14px;
    padding-right: 14px;
  }
  
  .pos{
    padding-left: 14px;
    padding-right: 14px;
    color: #00b14a;
    font-weight: 500;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
    padding: 8px 12px;
  }
`;
export default Wrapper
