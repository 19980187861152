import gpl from "graphql-tag";

// tạo két
const COFFER_CREATE_MUTATION = gpl`
  mutation createCoffer(
    $cofferName: String
    $cofferNote: String
    $cofferEnable: Boolean
    $cofferBranchId: Int
  ){
      createCoffer(
        cofferData:{
            cofferName: $cofferName
            cofferNote: $cofferNote
            cofferEnable: $cofferEnable
            cofferBranchId: $cofferBranchId
        }
        ){
        errors
        coffer{
            cofferId
            cofferName
            cofferNote
            cofferEnable
            cofferBranch{
                branchId
                branchName
            }
        }
      }
    }
`;

// sửa két
const COFFER_UPDATE_MUTATION = gpl`
  mutation updateCoffer(
    $cofferId: Int
    $cofferName: String
    $cofferNote: String
    $cofferEnable: Boolean
    $cofferBranchId: Int
  ){
      updateCoffer(
        cofferId: $cofferId
        cofferData:{
            cofferName: $cofferName
            cofferNote: $cofferNote
            cofferEnable: $cofferEnable
            cofferBranchId: $cofferBranchId
        }
        ){
        errors
        coffer{
            cofferId
            cofferName
            cofferNote
            cofferEnable
            cofferBranch{
                branchId
                branchName
            }
        }
      }
    }
`;

// xóa két
const COFFER_DELETE_MUTATION = gpl`
    mutation deleteCoffer($cofferIds: [Int]){
        deleteCoffer(cofferIds: $cofferIds){
            errors
             coffers{
                cofferId
            }
        }
    }
`;

// kích hoạt / vô hiệu hóa két
const COFFER_ENABLE_MUTATION = gpl`
    mutation enableCoffer($coffersData: [CofferInputEnable]){
        enableCoffer(coffersData: $coffersData){
            errors
        }
    }
`;

export {
    COFFER_CREATE_MUTATION,
    COFFER_UPDATE_MUTATION,
    COFFER_DELETE_MUTATION,
    COFFER_ENABLE_MUTATION,
}
