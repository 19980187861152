import gpl from "graphql-tag";
import {companySubscribePlanFieldDefault} from "../fragments/companySubscribePlanFragment";

export const COMPANY_SUBSCRIBE_PLAN_DETAIL = gpl`
    ${companySubscribePlanFieldDefault}
    query companySubscribePlan($id: Int){
        debtDetail(id: $id){
            ...companySubscribePlanFieldDefault
        }
    }
`;

export const COMPANY_SUBSCRIBE_PLANS = gpl`
    ${companySubscribePlanFieldDefault}
    query companySubscribePlans{
        companySubscribePlans{
            ...companySubscribePlanFieldDefault
        }
    }
`;

export const COMPANY_SUBSCRIBE_PLAN_PAGINATED = gpl` 
    ${companySubscribePlanFieldDefault}
    query companySubscribePlanPaginate(
        $branchId: Int
        $debtType: String
        $debtPaid: String
        $keyword: String
        $sort: String
        $order: String
        $dateFrom: Date
        $dateTo: Date
        $show: Int
        $page: Int
    ){
    debtsPaginate(
        branchId: $branchId
        debtType: $debtType
        debtPaid: $debtPaid
        keyword: $keyword
        sort: $sort
        order: $order
        dateFrom: $dateFrom
        dateTo: $dateTo
        show: $show
        page: $page
    ){
        page
        pages
        numberRecords
        hasNext
        hasPrev
        objects{
            ...debtFieldDefault
        }
    }
}`;

export const COMPANY_VERIFY_SUBSCRIBE_PLAN = gpl`
    ${companySubscribePlanFieldDefault}
    query companyVerify{
        companyVerify{
            ...companySubscribePlanFieldDefault
        }
    }
`;
