import React from 'react';
import {Spin} from "antd";

const Loading = () => (
    <Spin tip="Loading..." size="large">
        <div className="content" />
    </Spin>
);

export default Loading;

