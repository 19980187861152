import gpl from "graphql-tag";
import {userFieldDefault} from "./userFragment";

export const subscribePlanFieldDefault = gpl`
    ${userFieldDefault}
    fragment subscribePlanFieldDefault on SubscribePlanNode{
        id
        code
        name
        type
        expiry
        price
        maxUser
        maxBranch
        maxWarehouse
        description
        isDefault
        userCreated{
            ...userFieldDefault
        }
        userUpdated{
            ...userFieldDefault
        }
        createdAt
        updatedAt
    }
`;
