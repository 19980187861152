import gpl from "graphql-tag";

export const tableFieldDefault = gpl`
  fragment tableFieldDefault on TablesType{
    tableId
    tableName
    tableSerial
    tableNumberOfChair
    tableDescription
    tableEnable
    tableArea{
        areaId
        areaName
    }
    tableBranch{
        branchId
        branchName
    }
    trTable{
        timeFrom
        timeTo
        price
        description
    }
    tableUserCreated{
      username
    }
    tableCreatedAt
  }
`;

export const tableForPoSFieldDefault = gpl`
  fragment tableForPoSFieldDefault on salePosTable{
    tableId
    tableName
    tableEnable
    salePosTable{
      saleCode
      saleInvoiceCode
      saleStatus
      saleTemp
    }
  }
`;
