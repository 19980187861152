import React from 'react';
import ContentWrapper from "../../../Templates/ContentWrapper";
import {useTranslation} from "react-i18next";

const AccessDenied = ({agree}) => {
    const { t } = useTranslation();

    const onAgree = (e) => {
        if(agree){
            agree(e)
        }
    }

    return(
        <ContentWrapper>
            <div className="mt-3">
                <div className="text-center my-3">
                    <h1 className="mb-3 text-warning">
                        <em className="fa-2x mr-2 fas fa-shield-alt"/>
                    </h1>
                    <div className="text-bold text-md mb-3">{t('message.accessDenied')}</div>
                    <p className="lead m-0">
                        {t('common.Request denied, contact manager for more details')}
                    </p>
                    {agree &&
                        <button className="btn btn-sm btn-outline-primary mt-2" onClick={onAgree}>
                            {t('common.agree')}
                        </button>
                    }
                </div>
            </div>
        </ContentWrapper>
    )
};

export default AccessDenied;

