import Skeleton from '@material-ui/lab/Skeleton';
import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../../store/actions/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
const _ = require('lodash');

class SkeletonForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            rows: props.rows ? props.rows : 5,
            type: props.type ? props.type : "stacked"
        }
    }

    render() {
        return (
            this.props.configs.UI_LOADING_EFFECT ?
                <div style={{width: "100%"}}>
                    {
                        _.times(this.state.rows, (index) =>
                            <div key={index}>
                                {
                                    this.state.type === "horizontal" ?
                                        <div className="form-group">
                                            <Skeleton variant="rect" height={35}/>
                                        </div>
                                        :
                                        <div className="form-group">
                                            <Skeleton variant="rect" height={20}/>
                                            <Skeleton variant="rect" height={35}/>
                                        </div>
                                }

                            </div>
                        )
                    }
                </div>
                :
                <div className="text-center p-2">Đang tải...</div>
        )
    }
}

SkeletonForm.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, configs: state.configs});
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SkeletonForm));
