import gpl from "graphql-tag";
import {productCategoryFieldDefault, productCategoryFieldSimple} from "../fragments/productCategoryFragment";

// Lấy thông tin chi tiết nhóm hàng
export const PRODUCT_CATEGORY_DETAIL_QUERY = gpl`
    ${productCategoryFieldDefault}
    query productCategoryDetail($categoryId: Int){
    productCategoryDetail(categoryId: $categoryId){
        ...productCategoryFieldDefault
    }
}
`;

// Lấy danh sách tất cả nhóm hàng
export const PRODUCT_CATEGORIES_QUERY = gpl`
    ${productCategoryFieldSimple}
    query productCategories{
        productCategories{
            ...productCategoryFieldSimple
        }
    }
`;

// Lấy danh sách nhóm hàng (phân trang)
export const PRODUCT_CATEGORIES_PAGINATE_QUERY = gpl`
    ${productCategoryFieldDefault} 
    query productCategoriesPaginate(
        $keyword: String
        $sort: String
        $order: String
        $enable: String
        $show: Int
        $page: Int
    ){
    productCategoriesPaginate(
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ){
        page
        pages
        numberRecords
        hasNext
        hasPrev
        objects{
            ...productCategoryFieldDefault
        }
    }
}`;
