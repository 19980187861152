import gpl from "graphql-tag";

// danh sách két giữ tiền
export const COFFERS_QUERY = gpl`query coffers($branchId: Int){
        coffers(branchId: $branchId){
            cofferId
            cofferName
            cofferBranch{
                branchId
                branchName
            }
            cofferNote
            cofferEnable
        }
    }
`;

// chi tiết két giữ tiền
export const COFFER_DETAIL_QUERY = gpl`query coffer(
        $cofferId: Int
    ){
        coffer(
            cofferId: $cofferId
        ){
            cofferId
            cofferName
            cofferBranch{
                branchId
                branchName
            }
            cofferNote
            cofferEnable
        }
    }
`;

// danh sách két giữ tiền phân trang
export const COFFERS_PAGINATE_QUERY = gpl`query coffersPaginate(
    $branchId: Int
    $keyword: String
    $sort: String
    $order: String
    $enable: Boolean
    $show: Int
    $page: Int
){
    coffersPaginate(
        branchId: $branchId
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ){
        page
        pages
        hasNext
        hasPrev
        numberRecords
        keyword
        objects{
            cofferId
            cofferName
            cofferBranch{
                branchId
                branchName
            }
            cofferNote
            cofferEnable
            cofferCreatedAt
            cofferUserCreated{
                username
            }
        }
    }
}`;
