import gpl from "graphql-tag";
import {tableFieldDefault, tableForPoSFieldDefault} from "../fragments/tableFragment";

// Lấy chi tiết bàn phục vụ theo chi nhánh
export const TABLE_DETAIL_BY_BRANCH_QUERY = gpl`
    ${tableFieldDefault}
    query tableDetailByBranch($branchId: Int, $tableId: Int){
        tableDetailByBranch(branchId: $branchId, tableId: $tableId){
            ...tableFieldDefault
        }
    }
`;

// Lấy danh sách bàn phục vụ theo chi nhánh (phân trang)
export const TABLES_BY_BRANCH_PAGINATE_QUERY = gpl` 
    ${tableFieldDefault}
    query tablesByBranchPaginate(
        $branchId: Int
        $areaId: Int
        $keyword: String
        $sort: String
        $order: String
        $enable: String
        $show: Int
        $page: Int
    ){
    tablesByBranchPaginate(
        branchId: $branchId
        areaId: $areaId
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ){
        page
        pages
        numberRecords
        hasNext
        hasPrev
        objects{
            ...tableFieldDefault
        }
    }
}`;

// Lấy danh sách bàn phục vụ - theo chi nhánh
export const TABLES_FOR_POS_QUERY = gpl`
    ${tableForPoSFieldDefault}
    query tablesSaleAvailableByBranch(
            $branchId: Int
            $keyword: String
            $category: [Int]
            $show: String
            $sort: String
            $order: String
        ){
        tablesSaleAvailableByBranch(
            branchId: $branchId
            keyword: $keyword
            category: $category
            show: $show
            sort: $sort
            order: $order
        ){
            tables{
                ...tableForPoSFieldDefault
            }
        }
    }
`;

// Lấy danh sách bàn cho phép ghép - theo chi nhánh
export const TABLES_COMBINE_FOR_POS_QUERY = gpl`
    ${tableForPoSFieldDefault}
    query tablesCombineAvailableByBranch(
        $branchId: Int
        $keyword: String
        $category: [Int]
    ){
        tablesCombineAvailableByBranch(
            branchId: $branchId
            keyword: $keyword
            category: $category
        ){
            tables{
                ...tableForPoSFieldDefault
            }
        }
    }
`;

// Lấy danh sách bàn phục vụ theo chi nhánh
export const TABLES_BY_BRANCH_QUERY = gpl` 
    ${tableFieldDefault}
    query tablesByBranch(
        $branchId: Int
    ){
    tablesByBranch(
        branchId: $branchId
    ){
        ...tableFieldDefault
    }
}`;
