import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../../../store/actions/actions";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import SkeletonForm from "../../../Molecules/Skeleton/SkeletonForm";
import {UNIT_CREATE_MUTATION} from "../../../../api/units/unitMutation";
import {useMutation} from "@apollo/client";
import {Col, Divider, Form, Row, Input, Breadcrumb, Space, Button, Typography} from "antd";
import {CloseOutlined, HomeOutlined, SafetyCertificateOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const {Title} = Typography;

const Mousetrap = require("mousetrap");

const ActiveKey = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onSave = (values) => {

        let unitArgs = {
            activeKey: values.activeKey,
        }

        createUnit({
            variables: { unitArgs: unitArgs }
        }).then(result => {
            if (result && result.data){
                props.onCompleted(result.data)
            }
        }).finally(() => {
            form.resetFields();
        });

    };

    //Nếu có lỗi khi lưu dữ liệu
    const onError = (error) => {
        props.onError(error);
    };

    //Quay về trang chính của chức năng hiện tại
    const onGoHome = () => {
        props.onGoHome();
    };

    //Quay về trang trước đó
    const onCancel = () => {
        props.onCancel();
    };

    const [createUnit, {loading: loadingCreate}] = useMutation(UNIT_CREATE_MUTATION, {
        onError: onError
    });

    useEffect(() => {

        Mousetrap.bind(props.configs.UI_SHORTCUT_SAVE, (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
            document.getElementById("btnSave").click();
        });

        Mousetrap.bind(props.configs.UI_SHORTCUT_BACK, (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
            document.getElementById("btnBack").click();
        });

        return () => {
            Mousetrap.unbind(props.configs.UI_SHORTCUT_SAVE);
            Mousetrap.unbind(props.configs.UI_SHORTCUT_BACK);
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Kích hoạt gói dịch vụ | {process.env.REACT_APP_NAME}</title>
            </Helmet>

            <Row>
                <Col span={24}>
                    <Title level={4}>Kích hoạt gói dịch vụ</Title>
                </Col>

                <Col span={24}>
                    <Breadcrumb items={[
                        {
                            href: '/',
                            title: <HomeOutlined />,
                        },
                        {
                            title: <label onClick={onGoHome}>Gói dịch vụ</label>,
                        },
                        {
                            title: 'Đăng ký',
                        },
                    ]}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Space>
                        <Button type="primary" icon={<SafetyCertificateOutlined />}
                                onClick={() => {
                                    form.submit()
                                }}
                                id="btnSave"
                                disabled={loadingCreate}
                                loading={loadingCreate}
                        >
                            Kích hoạt
                        </Button>

                        <Button type="default"
                                icon={<CloseOutlined />}
                                onClick={onCancel}
                                id="btnBack"
                        >
                            {t('button.back')}
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Divider />

            {(loadingCreate) ? <SkeletonForm rows={1}/> :
                <Form form={form}
                      onFinish={onSave}
                      layout={"vertical"}
                      size={"large"}
                >
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Form.Item
                                name="activeKey"
                                label="KEY kích hoạt"
                                rules={[
                                    { required: true, message: "Vui lòng nhập KEY kích hoạt dịch vụ" },
                                ]}
                            >
                                <Input name="unitName" placeholder="Nhập KEY kích hoạt dịch vụ"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            }
        </>
    )
}

ActiveKey.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};
const mapStateToProps = state => ({ settings: state.settings, configs: state.configs, user: state.user,  menus: state.menus });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActiveKey));
