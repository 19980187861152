import React from "react";
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute ({component: Component, authed, ...rest}) {
    return (
        <Route
            {...rest}
            exact
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to="/login"/>
                /*<Redirect to={{pathname: '/login', state: {from: props.location}}} />*/
            }
        />
    )
}

export default PrivateRoute
