import gpl from "graphql-tag";

export const cashierFieldDefault = gpl`
  fragment cashierFieldDefault on CashierNode{
    cashierId
    cashierBranch{
        branchId
        branchName
    }
    cashierStaff{
        staffId
        staffCode
        staffName
    }
    cashierCoffer{
        cofferId
        cofferName
    }
    cashierDate
    cashierType
    cashierValue
    cashierDeviant
    cashierNote
    cashierCreatedAt
    cashierUserCreated{
        username
    }
    cashierParValues
  }
`;
