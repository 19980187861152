const Shortcut = [
    {
        name: 'Tạo mới',
        code: 'CREATE',
        key: ['ctrl+e'],
        description: 'Tạo mới danh mục, chứng từ, giao dịch,...'
    },
    {
        name: 'Sửa',
        code: 'UPDATE',
        key: ['ctrl+r'],
        description: 'Sửa một mục đang chọn'
    },
    {
        name: 'Xóa',
        code: 'DELETE',
        key: ['ctrl+h'],
        description: 'Xóa một hoặc nhiều mục đang chọn'
    },
    {
        name: 'Lưu',
        code: 'SAVE',
        key: ['ctrl+s'],
        description: 'Lưu lại khi thêm mới hoặc sửa'
    },
    {
        name: 'Lưu và tiếp tục',
        code: 'SAVE_AND_CONTINUE',
        key: ['ctrl+shift+s'],
        description: 'Lưu lại và tiếp tục khi thêm mới hoặc sửa'
    },
    {
        name: 'Đồng ý',
        code: 'AGREE',
        key: ['ctrl+i'],
        description: 'Xác nhận đồng ký khi có popup cần xác nhận thông tin'
    },
    {
        name: 'Hủy bỏ',
        code: 'CANCEL',
        key: ['ctrl+q'],
        description: 'Không đồng ký khi có popup cần xác nhận thông tin'
    },
    {
        name: 'Trở lại',
        code: 'BACK',
        key: ['ctrl+b'],
        description: 'Quay trở lại chức năng trước đó'
    },
    {
        name: 'Chọn/Hủy tất cả',
        code: 'ALL',
        key: ['ctrl+m'],
        description: 'Chọn/Hủy tất cả các mục (áp dụng cho danh sách)'
    },
    {
        name: 'Tìm kiếm',
        code: 'SEARCH',
        key: ['ctrl+f'],
        description: 'Tìm kiếm tại một chức năng cụ thể'
    },
    {
        name: 'In',
        code: 'PRINT',
        key: ['ctrl+p'],
        description: ''
    },
    {
        name: 'Mở trợ giúp',
        code: 'HELP',
        key: ['ctrl+u'],
        description: ''
    },
    {
        name: 'Di chuyển phải',
        code: 'MOVE_RIGHT',
        key: ['ctrl+]'],
        description: ''
    },
    {
        name: 'Di chuyển trái',
        code: 'MOVE_LEFT',
        key: ['ctrl+['],
        description: ''
    }
];
export default Shortcut;
