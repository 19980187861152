import gpl from "graphql-tag";

export const areaFieldDefault = gpl`
  fragment areaFieldDefault on AreasType{
    areaId
    areaName
    areaEnable
    areaDescription
    areaBranch{
        branchId
        branchName
    }
    tableArea{
        tableId
    }
    areaUserCreated{
      username
    }
    areaCreatedAt
  }
`;

export const areaFieldSimple = gpl`
  fragment areaFieldSimple on AreasType{
    areaId
    areaName
  }
`;
