import { USER_LOGIN } from '../actions/actions';

const initialUser = {
    isAuthenticated : false,
    username: "",
    exp: 0,
    origIat: 0,
    token: "",
    refreshExpiresIn: 0,
    firstName: "",
    lastName: "",
    isSuperuser: false,
    isStaff: false,
    isActive: false,
    isSubscribePlanExpired: false,
    email: "",
    groups: [],
    userPermissions: [],
    lastLogin: null,
    dateJoined: null,
    staff: {},
    permissions: {},
    branchActivate: {},
    company: {}
};

const userReducer = (state = initialUser, action) => {
    if (action.type === USER_LOGIN) {
        return {
            ...state,
            [action.name]: action.value
        }
    } else {
        return state;
    }
};

export default userReducer;
