import {CHANGE_TOUR} from '../actions/actions';

const tours = store => next => action => {
    let result = next(action);
    if (action.type === CHANGE_TOUR) {
        return (store.getState())
    }
    return result
};

export default tours;
