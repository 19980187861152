import gpl from "graphql-tag";
import {cashierFieldDefault} from "../fragments/cashierFragment";

export const CASHIER_CREATE_MUTATION = gpl`
  ${cashierFieldDefault}
  mutation createCashier(
    $cashierArgs: CashierArgs
  ){
      createCashier(
        cashierArgs: $cashierArgs
      ){
        errors
        cashier{
            ...cashierFieldDefault
        }
      }
    }
`;

export const CASHIER_UPDATE_MUTATION = gpl`
  ${cashierFieldDefault}
  mutation updateCashier(
    $cashierArgs: CashierArgs
  ){
      updateCashier(
        cashierArgs: $cashierArgs
      ){
        errors
        cashier{
            ...cashierFieldDefault
        }
      }
    }
`;

export const CASHIER_DELETE_MUTATION = gpl`
    mutation deleteCashier($cashierId: Int){
        deleteCashier(cashierId: $cashierId){
            errors
             cashier{
                cashierId
            }
        }
    }
`;
