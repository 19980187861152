import gpl from "graphql-tag";

const CONFIG_UPDATE_MUTATION = gpl`
  mutation updateConfigsByCode($configCode: String!, $configValue: String!){
      updateConfigsByCode(
        configCode: $configCode
        configValue: $configValue
      ){
        errors
      }
    }
`;

const CONFIG_RESET_MUTATION = gpl`
  mutation resetConfigs($configBranchId: Int, $configUserId: Int){
      resetConfigs(
        configBranchId: $configBranchId
        configUserId: $configUserId
      ){
        errors
        configs{
            configId
            configCode
            configName
            configDescription
            configValue
            configDefaultValue
            configUser{
                id
                username
                firstName
                lastName    
            }
            configBranch{
                branchId
                branchCode
                branchName
            }
        }
      }
    }
`;

export {
    CONFIG_UPDATE_MUTATION,
    CONFIG_RESET_MUTATION
}
