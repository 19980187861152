import { CHANGE_THEME } from '../actions/actions';

const initialState = {
    path: ''
}

const themesReducer = (state = initialState, action) => {
    if (action.type === CHANGE_THEME) {
        return {
            ...state,
            path: action.path
        }
    } else {
        return state;
    }
}

export default themesReducer;