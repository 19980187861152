import {USER_LOGIN} from "../actions/user.actions";

const user = store => next => action => {
    let result = next(action)
    if (action.type === USER_LOGIN) {
        return (store.getState())
    }
    return result
}

export default user;