import gpl from "graphql-tag";
import {productFieldDefault, productFieldSimple} from '../fragments/productFragment';

export const PRODUCT_DETAIL_QUERY = gpl`
    ${productFieldDefault}
    query productDetail($productId: Int){
        productDetail(productId: $productId){
            ...productFieldDefault
        }
    }
`;

export const PRODUCTS_PAGINATE_QUERY = gpl`
    ${productFieldDefault}
    query productsPaginate(
        $productType: String
        $categories: [Int]
        $brands: [Int]
        $keyword: String
        $sort: String
        $order: String
        $enable: String
        $show: Int
        $page: Int
    ){
    productsPaginate(
        productType: $productType
        categories: $categories
        brands: $brands
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ){
        page
        pages
        numberRecords
        hasNext
        hasPrev
        objects{
            ...productFieldDefault
        }
    }
}`;

// Tạo mã hàng hóa (SKU)
export const PRODUCT_GENERATE_SKU_QUERY = gpl`
    query productsGenerateSku($category: String){
        productsGenerateSku(category: $category)
    }
`;

// Lấy hàng hóa là nguyên vật liệu dùng để định lượng hàng hóa
export const PRODUCTS_IS_MATERIAL_QUERY = gpl`
    query productsIsMaterial($keyword: String){
        productsIsMaterial(keyword: $keyword){
            productId
            productSku
            productName
            productShortName
            productImage
            productBuyPrice
            productBuyAvailable
            productSellPrice
            productWholesalePrice
            productSellAvailable
            productDescription
            productUnit{
                unitId
                unitName
            }
            productBrand{
                brandId
                brandName
            }
            productSupplier{
                supplierId
                supplierName
            }
            productType
            productMinimumInventory
            productWarehouseDefault{
                warehouseId,
                warehouseName
            }
            productTrackInventory
            productCategory{
                categoryId,
                categoryName
            }
            productWeight
            productSizeLength
            productSizeWidth
            productSizeHeight
            productEnable
      }
    }
`;

// Lấy hàng hóa để thêm vào Combo
export const PRODUCTS_FOR_COMBO_QUERY = gpl`
    query productsForCombo($keyword: String){
        productsForCombo(keyword: $keyword){
            productId
            productSku
            productName
            productShortName
            productImage
            productBuyPrice
            productBuyAvailable
            productSellPrice
            productWholesalePrice
            productSellAvailable
            productDescription
            productUnit{
                unitId
                unitName
            }
            productBrand{
                brandId
                brandName
            }
            productSupplier{
                supplierId
                supplierName
            }
            productType
            productMinimumInventory
            productWarehouseDefault{
                warehouseId,
                warehouseName
            }
            productTrackInventory
            productCategory{
                categoryId,
                categoryName
            }
            productWeight
            productSizeLength
            productSizeWidth
            productSizeHeight
            productEnable
      }
    }
`;

// Lấy danh sách hàng hóa
export const PRODUCTS_HAS_BARCODE_QUERY = gpl`
    query productsHasBarcode($keyword: String, $category: [Int]){
        productsHasBarcode(keyword: $keyword, category: $category){
        objects
            {
                productId,
                productSku,
                productName,
                productBarcode,
                productSellPrice,
                productUnit{
                  unitId,
                  unitName,
                  unitSymbol
                },
                productCategory{
                  categoryId,
                  categoryName
                }
            }
        }
    }
`;

// Lấy danh sách hàng hóa có thể bán được theo điều kiện
export const PRODUCTS_FOR_SALE_QUERY = gpl`
    query productsSaleAvailable(
        $branch: Int
        $priceBook: Int
        $typeProduct: String
        $keyword: String 
        $category: [Int]
        $sort: String
        $order: String
        $limit: Int
    ){
        productsSaleAvailable(
            branch: $branch
            priceBook: $priceBook
            typeProduct: $typeProduct
            keyword: $keyword
            category: $category
            sort: $sort
            order: $order
            limit: $limit
        ){
            objects
            {
                productId
                productSku
                productName
                productImage
                productSellPrice
                productUnit{
                  unitId
                  unitName
                  unitSymbol
                }
                productCategory{
                  categoryId
                  categoryName
                }
            }
            priceBooks{
                ppkPriceBook{
                    pbkId
                }
                ppkProduct{
                    productId
                }
                ppkPrice
            }
        }
    }
`;

// Lấy danh sách hàng hóa có thể bán được theo điều kiện
export const PRODUCTS_FOR_SALE_PAGINATE_QUERY = gpl`
    query productsSaleAvailablePaginate(
        $branch: Int
        $priceBook: Int
        $typeProduct: String
        $keyword: String 
        $category: [Int]
        $sort: String
        $order: String
        $show: Int
        $page: Int
    ){
        productsSaleAvailablePaginate(
            branch: $branch
            priceBook: $priceBook
            typeProduct: $typeProduct
            keyword: $keyword
            category: $category
            sort: $sort
            order: $order
            show: $show
            page: $page
        ){
            objects
            {
                productId
                productSku
                productName
                productImage
                productSellPrice
                productUnit{
                  unitId
                  unitName
                  unitSymbol
                }
                productCategory{
                  categoryId
                  categoryName
                }
            }
            priceBooks{
                ppkPriceBook{
                    pbkId
                }
                ppkProduct{
                    productId
                }
                ppkPrice
            }
            page
            pages
            numberRecords
        }
    }
`;

// Lấy danh sách hàng hóa có thể nhập kho theo điều kiện
export const PRODUCTS_RECEIVING_AVAILABLE_BY_BRANCH_QUERY = gpl`
    query productsReceiving(
        $branch: Int
        $keyword: String
        $category: [Int]
    ){
        productsReceiving(
            branch: $branch
            keyword: $keyword
            category: $category
        ){
        objects
            {
                product{
                    productId,
                    productSku,
                    productName,
                    productImage,
                    productSellPrice,
                    productBuyPrice,
                    productUnit{
                      unitId,
                      unitName,
                      unitSymbol
                    },
                    productCategory{
                      categoryId,
                      categoryName
                    }
                    productSerialImeiEnable
                    productShipmentEnable
                }
                inventory
            }
        }
    }
`;

// Lấy danh sách hàng hóa có thể nhập kho theo điều kiện
export const PRODUCTS_RECEIVING_RETURN_AVAILABLE_BY_BRANCH_QUERY = gpl`
    query productsReceivingReturn(
        $branch: Int
        $keyword: String
        $category: [Int]
    ){
        productsReceivingReturn(
            branch: $branch
            keyword: $keyword
            category: $category
        ){
        objects
            {
                product{
                    productId
                    productSku
                    productName
                    productImage
                    productSellPrice
                    productBuyPrice
                    productUnit{
                      unitId
                      unitName
                      unitSymbol
                    }
                    productCategory{
                      categoryId
                      categoryName
                    }
                    productSerialImeiEnable
                    productShipmentEnable
                }
                inventory
            }
        }
    }
`;

// Lấy danh sách hàng hóa có thể xuất kho theo điều kiện
export const PRODUCTS_DELIVERY_AVAILABLE_BY_BRANCH_QUERY = gpl`
    query productsDelivery(
        $branch: Int
        $keyword: String
        $category: [Int]
    ){
        productsDelivery(
            branch: $branch
            keyword: $keyword
            category: $category
        ){
        objects
            {
                product{
                    productId
                    productSku
                    productName
                    productImage
                    productSellPrice
                    productBuyPrice
                    productUnit{
                      unitId
                      unitName
                      unitSymbol
                    }
                    productCategory{
                      categoryId
                      categoryName
                    }
                }
                inventory
            }
        }
    }
`;

// Lấy danh sách hàng hóa có thể chuyển kho theo điều kiện
export const PRODUCTS_TRANSFER_AVAILABLE_BY_BRANCH_QUERY = gpl`
    query productsTransfer(
        $branch: Int
        $keyword: String
        $category: [Int]
    ){
        productsTransfer(
            branch: $branch
            keyword: $keyword
            category: $category
        ){
        objects
            {
                product{
                    productId
                    productSku
                    productName
                    productImage
                    productSellPrice
                    productBuyPrice
                    productUnit{
                      unitId
                      unitName
                      unitSymbol
                    }
                    productCategory{
                      categoryId
                      categoryName
                    }
                }
                inventory
            }
        }
    }
`;

// Lấy danh sách hàng hóa có thể xuất bán/trả hàng từ kho
export const PRODUCTS_DELIVERY_SALE_QUERY = gpl`
    query productsInventoryAvailable(
        $branch: Int
        $priceBook: Int
        $typeProduct: String
        $keyword: String 
        $category: [Int]
        $sort: String
        $order: String
        $limit: Int
    ){
        productsInventoryAvailable(
            branch: $branch
            priceBook: $priceBook
            typeProduct: $typeProduct
            keyword: $keyword
            category: $category
            sort: $sort
            order: $order
            limit: $limit
        ){
            objects
            {
                productId
                productSku
                productName
                productImage
                productSellPrice
                productUnit{
                  unitId
                  unitName
                  unitSymbol
                }
                productCategory{
                  categoryId
                  categoryName
                }
            }
            priceBooks{
                ppkPriceBook{
                    pbkId
                }
                ppkProduct{
                    productId
                }
                ppkPrice
            }
        }
    }
`;

export const PRODUCT_DETAIL_BY_BARCODE_QUERY = gpl`query productDetailByBarcode($productBarcode: String){
    productDetailByBarcode(productBarcode: $productBarcode){
        productId
        productSku
        productBarcode
        productName
        productShortName
        productImage
        productBuyPrice
        productBuyAvailable
        productSellPrice
        productWholesalePrice
        productSellAvailable
        productDescription
        productUnit{
            unitId
            unitName
        }
        productShelve{
            shelveId
            shelveName
        }
        productBrand{
            brandId
            brandName
        }
        productSupplier{
            supplierId
            supplierName
        }
        productType
        productMinimumInventory
        productMaximumInventory
        productWarehouseDefault{
            warehouseId
            warehouseName
        }
        productTrackInventory
        productCategory{
            categoryId
            categoryName
        }
        pqProduct{
            pqId
            pqProductMaterial{
                productId
                productName
            }
            pqProductMaterialUnit{
                unitId
                unitName
            }
            pqQuantity
            pqStatus
        }
        phaProduct{
          phaId
          phaAttribute{
            attrId
            attrName
            attrDatatype
          }
          paIntFromAttribute{
            paIntValue
          }
          paDecimalFromAttribute{
            paDecimalValue
          }
          paCharFromAttribute{
            paCharValue
          }
          paTextFromAttribute{
            paTextValue
          }
          paDatetimeFromAttribute{
            paDatetimeValue
          }
        }
        pcuProduct{
          pcuId
          pcuUnitRoot{
            unitId
            unitName
          }
          pcuUnitConversion{
            unitId
            unitName
          }
          pcuCalculation
          pcuRate
          pcuPrice
          pcuSellPrice
          pcuDescription
        }
        pgProduct{
            pgId
            pgImage
            pgDescription
            pgSerial
        }
        productsSet{
          productId
          phaProduct{
            phaId
            phaAttribute{
              attrId
              attrName
              attrDatatype
            }
            phaSpecification
            paCharFromAttribute{
              paCharValue
            }
          }
        }
        productWeight
        productSizeLength
        productSizeWidth
        productSizeHeight
        productExp
        productOrigin{
            countryId
            countryCode
            countryName
        }
        productSerialImeiEnable
        productAccumulatePointsEnable
        productBonusPoint
        productShipmentEnable
        productEnable
    }
}
`;

//Thông tin tồn kho hàng hóa
export const PRODUCT_INVENTORY_QUERY = gpl`
    query productInventory(
        $productId: Int
        $branchId: Int
        $warehouseId: Int
    ){
        productInventory(
            productId: $productId
            branchId: $branchId
            warehouseId: $warehouseId
        ){
            product{
              productId
              productName
            }
            warehouse{
              warehouseId
              warehouseName
            }
            branchId
            inventory
      }
    }
`;

// Lấy hàng hóa theo nhà cung cấp
export const PRODUCTS_BY_SUPPLIER_QUERY = gpl`
    query productsBySupplier(
        $supplierId: Int
        $keyword: String
    ){
        productsBySupplier(
            supplierId: $supplierId
            keyword: $keyword
        ){
            productId
            productSku
            productName
            productShortName
            productImage
            productBuyPrice
            productBuyAvailable
            productSellPrice
            productWholesalePrice
            productSellAvailable
            productDescription
            productUnit{
                unitId
                unitName
            }
            productBrand{
                brandId
                brandName
            }
            productSupplier{
                supplierId
                supplierName
            }
            productType
            productMinimumInventory
            productWarehouseDefault{
                warehouseId,
                warehouseName
            }
            productTrackInventory
            productCategory{
                categoryId,
                categoryName
            }
            productWeight
            productSizeLength
            productSizeWidth
            productSizeHeight
            productBonusPoint
            productEnable
      }
    }
`;

// Lấy danh sách hàng hóa
export const PRODUCTS_QUERY = gpl`
    ${productFieldSimple}
    query products(
        $limit: Int
        $order: String
        $sort: String
        $keyword: String
    ){
        products(
            limit: $limit
            order: $order
            sort: $sort
            keyword: $keyword
        ){
            ...productFieldSimple
        }
    }
`;

// Lấy danh sách hàng hóa
export const PRODUCTS_POS_QUERY = gpl`
    ${productFieldDefault}
    query products(
        $limit: Int
        $order: String
        $sort: String
        $keyword: String
    ){
        products(
            limit: $limit
            order: $order
            sort: $sort
            keyword: $keyword
        ){
            ...productFieldDefault
        }
    }
`;
