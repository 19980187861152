import gpl from "graphql-tag";

// Tạo đơn vị tính
export const UNIT_CREATE_MUTATION = gpl`
  mutation createUnit($unitArgs: UnitArgs){
      createUnit(unitArgs: $unitArgs){
        errors
        unit{
            unitId
            unitSymbol
            unitName
            unitEnable
        }
      }
    }
`;

// Sửa đơn vị tính
export const UNIT_UPDATE_MUTATION = gpl`
  mutation updateUnit($unitArgs: UnitArgs){
      updateUnit(unitArgs: $unitArgs){
        errors
        unit{
            unitId
            unitName
            unitDescription
            unitSymbol
            unitEnable
            unitStatus
        }
      }
    }
`;

// Kích hoạt/Vô hiệu đơn vị tính
export const UNIT_ENABLE_MUTATION = gpl`
    mutation enableUnit($units: [UnitEnableArgs]){
      enableUnit(units: $units){
        errors
      }
    }
`;

// Xóa mền đơn vị tính
export const UNIT_DELETE_MUTATION = gpl`
    mutation deleteUnit($unitId: [Int]){
      deleteUnit(unitId: $unitId){
        errors
        units{
            unitId
        }
      }
    }
`;
