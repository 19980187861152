import gpl from "graphql-tag";

export const customerFieldSimple = gpl`
  fragment customerFieldSimple on CustomersModel{
    customerId
    customerCode
    customerName
    customerAvatar
    customerPhone
    customerMobile
  }
`;


export const customerFieldDefault = gpl`
  fragment customerFieldDefault on CustomersModel{
    customerId
    customerCode
    customerVipCode
    customerName
    customerAvatar
    customerPhone
    customerMobile
    customerEmail
    customerPosition
    customerTax
    customerWebsite
    customerMobile
    customerGender
    customerDateOfBirth
    customerIdCard
    customerIdCardPlace
    customerIdCardDate
    customerCompanyName
    customerBank{
        bankId
        bankCode
        bankNameVn
    }
    customerBankNum
    customerDescription
     customerProvince{
        provinceId
        provinceName
    }
    customerDistrict{
        districtId
        districtName
    }
    customerVillage{
        wardsId
        wardsName
    }
    customerType
    customerLevel{
        customerLevelId
        customerLevelName
    }
    customerGroups{
        cusGroupId
        cusGroupName
    }
    customerAddress
    customerEnable
    customerCreatedAt
    customerUserCreated{
        username
    }
  }
`;


export const customerFieldDetail = gpl`
  fragment customerFieldDetail on CustomersModel{
    customerId
    customerCode
    customerVipCode
    customerName
    customerAvatar
    customerPhone
    customerMobile
    customerEmail
    customerPosition
    customerTax
    customerWebsite
    customerMobile
    customerGender
    customerDateOfBirth
    customerIdCard
    customerIdCardPlace
    customerIdCardDate
    customerCompanyName
    customerBank{
        bankId
        bankCode
        bankNameVn
    }
    customerBankNum
    customerDescription
     customerProvince{
        provinceId
        provinceName
    }
    customerDistrict{
        districtId
        districtName
    }
    customerVillage{
        wardsId
        wardsName
    }
    customerType
    customerLevel{
        customerLevelId
        customerLevelName
    }
    customerGroups{
        cusGroupId
        cusGroupName
    }
    customerAddress
    debtCustomer{
        id
        code
        type
        date
        value
        note
        paid
        branch{
            branchCode
            branchName
        }
        dtDebt{
            id
            date
            value
            note
            deleted
        }
        deleted
    }
    customerEnable
    customerCreatedAt
    customerUserCreated{
        username
    }
  }
`;
