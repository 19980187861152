import { MENU_NAV } from '../actions/actions';

const initialMenu = {
    "menus": []
};

const menuReducer = (state = initialMenu, action) => {
    if (action.type === MENU_NAV) {
        return {
            ...state,
            [action.name]: action.value
        }
    } else {
        return state;
    }
}

export default menuReducer;