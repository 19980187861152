import {CHANGE_TOUR} from '../actions/actions';

const initialTours = {
    INVENTORY_PRODUCT_LIST: true,
    INVENTORY_PRODUCT_CREATE: true,
    INVENTORY_PRODUCT_DETAIL: true,
    INVENTORY_PRODUCT_UPDATE: true,
    INVENTORY_PRODUCT_IMPORT: true,

    FINANCE_DASHBOARD: true,

    MARKETING_DASHBOARD: true,

    ASSET_DASHBOARD: true,
};

const toursReducer = (state = initialTours, action) => {
    if (action.type === CHANGE_TOUR) {
        return {
            ...state,
            [action.name]: action.value
        }
    } else {
        return state;
    }
};

export default toursReducer;
