import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import "./Vendor";
import './styles/bootstrap.scss';
import './styles/app.scss'
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'
//import {Adsense} from '@ctrl/react-adsense';
//import { GoogleOAuthProvider } from '@react-oauth/google';
import {withTranslation} from "react-i18next";

const token = localStorage.getItem('token');

const endpointDefault = createUploadLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        authorization: token ? `JWT ${token}` : '',
    }
})

const client = new ApolloClient({
    link: endpointDefault,
    cache: new InMemoryCache(),
});

class App extends Component {
  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    // global PUBLIC_URL
    const basename = process.env.REACT_APP_NODE_ENV === 'development' ? '/' : (process.env.REACT_APP_PUBLIC_URL || '/');

    return (
        <BrowserRouter basename={basename}>
            <ApolloProvider client={client}>
                <Routes />
            </ApolloProvider>
        </BrowserRouter>
    );
  }
}

export default withTranslation()(App);
