import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../store/actions/actions";
import {connect} from "react-redux";

const ActionLoading = (props) => {

    useEffect(() => {
        props.actions.changeSetting("isActionLoading", false);
    }, []);

    return(
        <div className="action-loading-bottom" hidden={!props.settings.isActionLoading}>
            <div className="background-loading bg-muted-700"/>
            <div className="text-loading text-muted-700">
                <em className="fas fa-circle-notch fa-spin"/> Đang xử lý...
            </div>
        </div>
    );
};

ActionLoading.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
};

const mapStateToProps = state => ({settings: state.settings});
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionLoading);
