import gpl from "graphql-tag";
import {printFormFieldDefault} from "../fragments/printFormFragment";

export const PRINT_FORM_DETAIL_QUERY = gpl` 
    ${printFormFieldDefault}
    query printFormDetail(
        $printFormId: Int
    ){
    printFormDetail(
        printFormId: $printFormId
    ){
        ...printFormFieldDefault
    }
}`;

export const PRINT_FORM_DEFAULT_QUERY = gpl` 
    ${printFormFieldDefault}
    query printFormDefaultByType(
        $printFormType: String
    ){
    printFormDefaultByType(
        printFormType: $printFormType
    ){
        ...printFormFieldDefault
    }
}`;

export const PRINT_FORMS_QUERY = gpl` 
    ${printFormFieldDefault}
    query printForms{
        printForms{
            ...printFormFieldDefault
        }
    }`;

export const PRINT_FORMS_PAGINATES_QUERY = gpl` 
    ${printFormFieldDefault}
    query printFormsPaginate(
        $keyword: String
        $default: String
        $type: String
        $sort: String
        $order: String
        $show: Int
        $page: Int
    ){
    printFormsPaginate(
        keyword: $keyword
        default: $default
        type: $type
        sort: $sort
        order: $order
        show: $show
        page: $page
    ){
        page
        pages
        numberRecords
        hasNext
        hasPrev
        objects{
            ...printFormFieldDefault
        }
    }
}`;

export const RENDER_PRINT_SALE_QUERY = gpl`
    query renderPrintSale(
        $invoiceCodeSystem: String
    ){
    renderPrintSale(
        invoiceCodeSystem: $invoiceCodeSystem
    ){
        html
    }
}`;

export const RENDER_PRINT_SALE_MOBILE_QUERY = gpl`
    query renderPrintPosMobile(
        $code: String
        $token: String
    ){
    renderPrintPosMobile(
        code: $code
        token: $token
    ){
        html
    }
}`;

export const RENDER_PRINT_POS_QUERY = gpl`
    query renderPrintPos(
        $invoiceCodeSystem: String
    ){
    renderPrintPos(
        invoiceCodeSystem: $invoiceCodeSystem
    ){
        html
    }
}`;

export const RENDER_PRINT_POS_TEMP_QUERY = gpl`
    query renderPrintPosTemp(
        $invoiceCodeSystem: String
    ){
    renderPrintPosTemp(
        invoiceCodeSystem: $invoiceCodeSystem
    ){
        html
    }
}`;

export const RENDER_PRINT_POS_KITCHEN_QUERY = gpl`
    query renderPrintPosKitchen(
        $invoiceCodeSystem: String
    ){
    renderPrintPosKitchen(
        invoiceCodeSystem: $invoiceCodeSystem
    ){
        html
    }
}`;

export const RENDER_PRINT_RECEIPT_QUERY = gpl`
    query renderPrintReceipt(
        $cashBookId: Int
    ){
    renderPrintReceipt(
        cashBookId: $cashBookId
    ){
        html
    }
}`;

export const RENDER_PRINT_PAYMENT_QUERY = gpl`
    query renderPrintPayment(
        $cashBookId: Int
    ){
    renderPrintPayment(
        cashBookId: $cashBookId
    ){
        html
    }
}`;

export const RENDER_PRINT_STOCK_QUERY = gpl`
    query renderPrintStock(
        $inventoryCode: String
    ){
    renderPrintStock(
        inventoryCode: $inventoryCode
    ){
        html
    }
}`;

export const RENDER_PRINT_ORDER_QUERY = gpl`
    query renderPrintOrder(
        $id: Int
    ){
    renderPrintOrder(
        id: $id
    ){
        html
    }
}`;
