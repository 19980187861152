import {CHANGE_CONFIG} from '../actions/actions';

const initialConfigs = {
    UI_LOGO: "",
    UI_COLOR: "themes/theme-e.css",
    UI_LANGUAGE: "vn",
    UI_LOADING_EFFECT: true,
    UI_HEADER_FIXED: false,
    UI_NAVIGATION_FIXED: false,
    UI_DATE_FORMAT: "dd/mm/yyyy",
    UI_THOUSAND_SEPARATOR: ".",
    UI_DECIMAL_SEPARATOR: ",",
    UI_DECIMAL_SCALE: 2,
    UI_DEFAULT_NUMBER_RECORDS: 20,
    UI_OPTION_NUMBER_RECORDS: [10,20,50,100,500],
    UI_VIEW_MODE: "list",
    UI_VIEW_DETAIL: "redirect",
    UI_VIEW_CREATE: "redirect",
    UI_VIEW_UPDATE: "redirect",
    UI_HOME_MENU_MODE: "ICON",
    UI_COLUMN_EXTEND: false,
    UI_SALE_NOTIFY_SOUND_PAYMENT: true,
    UI_SHORTCUT_AGREE: ["ctrl+i"],
    UI_SHORTCUT_ALL: ["ctrl+m"],
    UI_SHORTCUT_BACK: ["ctrl+b"],
    UI_SHORTCUT_CANCEL: ["ctrl+q"],
    UI_SHORTCUT_CREATE: ["ctrl+e"],
    UI_SHORTCUT_DELETE: ["ctrl+h"],
    UI_SHORTCUT_HELP: ["ctrl+u"],
    UI_SHORTCUT_PRINT: ["ctrl+p"],
    UI_SHORTCUT_SAVE: ["ctrl+s"],
    UI_SHORTCUT_SAVE_AND_CONTINUE: ["ctrl+shift+s"],
    UI_SHORTCUT_SEARCH: ["ctrl+f"],
    UI_SHORTCUT_UPDATE: ["ctrl+r"],
};

const configsReducer = (state = initialConfigs, action) => {
    if (action.type === CHANGE_CONFIG) {
        return {
            ...state,
            [action.name]: action.value
        }
    } else {
        return state;
    }
};

export default configsReducer;
