import gpl from "graphql-tag";

export const userFieldDefault = gpl`
  fragment userFieldDefault on UserJoinedModel{
    id
    username
    firstName
    lastName
    email
  }
`;

export const userFieldModel = gpl`
  fragment userFieldModel on UserModel{
    id
    username
    firstName
    lastName
    email
    isActive
    lastLogin
    dateJoined
    groups{
        id
        name
    }
  }
`;
