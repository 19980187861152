import Skeleton from '@material-ui/lab/Skeleton';
import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../../store/actions/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Loading from "../../Pages/System/Pages/Loading";
const _ = require('lodash');

class SkeletonTable extends Component {
    render() {
        return (
            this.props.configs.UI_LOADING_EFFECT ?
                <div style={{width: "100%"}}>
                    <table style={{width: "100%"}}>
                        <tbody>
                            {
                                _.range(1, (this.props.rows && this.props.rows > 0) ? this.props.rows : 10).map((key) =>
                                    <tr key={key}>
                                        <td>
                                            <Skeleton variant="rect" height={47}/>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                </div>
                :
                <Loading/>
        )
    }
}

SkeletonTable.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, configs: state.configs});
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SkeletonTable));
