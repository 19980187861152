import gpl from "graphql-tag";

// Lấy thông tin chi tiết bảng giá
const PRICE_BOOK_DETAIL_QUERY = gpl`query priceBookDetail($pbkId: Int){
    priceBookDetail(pbkId: $pbkId){
        pbkId
        pbkEffectiveFrom
        pbkEffectiveTo
        pbkRangeBranch{
            branchId
            branchName
        }
        pbkRangeStaff{
            staffId
            staffCode
            staffName
        }
        pbkRangeCustomer{
            customerId
            customerCode
            customerName
        }
        pbkName
        pbkDescription
        pbkAllowOutRange
        pbkOutRangeAlert
        pbkEnable
        pbkStatus
    }
}
`;

// Lấy danh sách bảng giá (phân trang)
const PRICE_BOOKS_PAGINATE_QUERY = gpl` query priceBooksPaginate(
        $keyword: String, $sort: String, $order: String, $enable: String, $show: Int, $page: Int
    ){
    priceBooksPaginate(keyword: $keyword, sort: $sort, order: $order, enable: $enable, show: $show, page: $page){
        page,
        pages,
        numberRecords
        hasNext,
        hasPrev,
        objects{
            pbkId
            pbkEffectiveFrom
            pbkEffectiveTo
            pbkRangeBranch{
                branchId
                branchCode
                branchName
            }
            pbkRangeStaff{
                staffId
                staffCode
                staffName
            }
            pbkRangeCustomer{
                customerId
                customerCode
                customerName
            }
            pbkName
            pbkDescription
            pbkAllowOutRange
            pbkOutRangeAlert
            pbkEnable
            pbkUserCreated{
              username
            }
            pbkCreatedAt
        }
    }
}`;

//Lấy danh sách bảng giá bao gồm bảng giá chưa có hàng hóa được thiết lập
const PRICE_BOOK_CUSTOM_QUERY = gpl`
    query priceBookCustomByProduct($productId: Int){
        priceBookCustomByProduct(productId: $productId){
            productPriceBook{
                pbkId
                pbkName
                productId
                productName
                ppkPrice
            }
        }
    }
`;

//Lấy danh sách tất cả bảng giá
const PRICE_BOOKS_QUERY = gpl`
    query priceBooks{
        priceBooks{
            pbkId
            pbkName
            pbkDescription
            pbkEffectiveFrom
            pbkEffectiveTo
        }
    }
`;

//Lấy danh sách bảng giá bán hàng
const PRICE_BOOKS_FOR_SALE_QUERY = gpl`
    query priceBooksForSale(
        $branchId: Int
        $staffId: Int
        $customerId: Int
    ){
        priceBooksForSale(
            branchId: $branchId
            staffId: $staffId
            customerId: $customerId
        ){
            pbkId
            pbkName
            pbkDescription
            pbkEffectiveFrom
            pbkEffectiveTo
        }
    }
`;

// Lấy danh sách hàng hóa theo bảng giá (phân trang)
const PRODUCTS_BY_PRICE_BOOK_PAGINATE_QUERY = gpl` query productsByPriceBookPaginate(
        $pbkId: Int
        $keyword: String
        $category: [Int]
        $sort: String
        $order: String
        $show: Int
        $page: Int
    ){
    productsByPriceBookPaginate(
        pbkId: $pbkId
        keyword: $keyword
        category: $category
        sort: $sort
        order: $order
        show: $show
        page: $page
    ){
        page
        pages
        numberRecords
        products{
            productId
            productSku
            productName
            unitName
            sellPrice
            priceBook
        }
    }
}`;

export {
    PRICE_BOOK_DETAIL_QUERY,
    PRICE_BOOKS_PAGINATE_QUERY,
    PRICE_BOOK_CUSTOM_QUERY,
    PRICE_BOOKS_QUERY,
    PRICE_BOOKS_FOR_SALE_QUERY,
    PRODUCTS_BY_PRICE_BOOK_PAGINATE_QUERY
}
