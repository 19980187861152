import {MENU_NAV} from "../actions/menus.actions";

const menus = store => next => action => {
    let result = next(action)
    if (action.type === MENU_NAV) {
        return (store.getState())
    }
    return result
}

export default menus;