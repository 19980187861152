import gpl from "graphql-tag";

export const productCategoryFieldDefault = gpl`
  fragment productCategoryFieldDefault on ProductCategoryType{
    categoryId
    categoryName
    categoryImage
    categoryParent{
        categoryId
        categoryName
    }
    categoryDescription
    categoryEnable
    categoryStatus
    productsSet{
        productId
    }
    categoryUserCreated{
      username
    }
    categoryCreatedAt
  }
`;

export const productCategoryFieldSimple = gpl`
  fragment productCategoryFieldSimple on ProductCategoryType{
    categoryId
    categoryName
    categoryEnable
  }
`;
