import {Card, CardBody, CardFooter, Col, Input, Row} from "reactstrap";
import React, {useCallback, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../../store/actions/actions";
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {BRANCHES_FOR_STAFF_QUERY} from "../../../api/branches/branchQuery";
import {useQuery} from "@apollo/client";
import SkeletonTable from "../../Molecules/Skeleton/SkeletonTable";
import DataEmpty from "../../Common/DataEmpty";
const _ = require('lodash');

const ListBranchForStaff = ({headerFixed, onChange}) => {
    const [popupHeight, setPopupHeight] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [formSearch, setFormSearch] = useState({
        keyword: ""
    });
    const [branches, setBranches] = useState([]);

    const onLoadBranchesData = (data) => {
        setBranches(data.branchesForStaff);
    };

    const onChangeKeyword = useCallback(_.debounce((value) => {
        setFormSearch({
            ...formSearch,
            keyword: value
        })
    }, 500), [formSearch])

    const updateWindowDimensions = () => {
        //setWidth(window.innerWidth);
        //setHeight(window.innerHeight);
        setPopupHeight((window.innerHeight - 240));
    };

    const { loading: loadingBranch, data: dataBranch } = useQuery(BRANCHES_FOR_STAFF_QUERY, {
        variables: {
            keyword: formSearch.keyword
        },
        onCompleted: onLoadBranchesData,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache"
    });

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        if(dataBranch){
            onLoadBranchesData(dataBranch)
        }

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        }
    }, [dataBranch]);

    return (
        <div className={headerFixed ? "card card-default card-fixed m-0" : "card card-default m-0"}>
            <div className={headerFixed ? "card-header header-fixed" : "card-header"}>
                <div className="nav-sub">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <h4 className="font-weight-normal">
                                    <label className="text-primary cursor-pointer mb-0">
                                        Danh sách chi nhánh
                                    </label>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input className="form-control"
                                   name="searchBranch"
                                   placeholder="Tìm chi nhánh..."
                                   value={keyword}
                                   onChange={(e) => {
                                       setKeyword(e.target.value);
                                       onChangeKeyword(e.target.value)
                                   }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div style={{height: popupHeight}}>
                    <div className="auto-scroll">
                        {loadingBranch ? <SkeletonTable/> :
                            <Row>
                                {(!_.isNil(branches) && branches.length > 0) ?
                                    branches.map((item, key) =>
                                        <Col lg="6" sm="12" key={key}>
                                            <Card className="card-default">
                                                <CardBody className="text-center">
                                                    {
                                                        (item.branchAvatar !== "[]" && item.branchAvatar !== "") ?
                                                            <img className="mb-2 img-fluid rounded-circle thumb64"
                                                                 src={process.env.REACT_APP_BASE_URL + item.branchAvatar}
                                                                 alt="Chi nhánh"/> :
                                                            <img className="mb-2 img-fluid rounded-circle thumb64"
                                                                 src={"/img/avatar-placeholder.png"}
                                                                 alt="Chi nhánh"/>
                                                    }

                                                    <h4>{item.branchCode}</h4>
                                                    <p>{item.branchName}</p>
                                                </CardBody>
                                                <CardFooter>
                                                    <div className="text-center">
                                                        <button type="button" className="btn btn-primary" onClick={() => {
                                                            onChange({
                                                                "branchCode": item.branchCode,
                                                                "branchId": item.branchId,
                                                                "branchName": item.branchName,
                                                                "branchAvatar": item.branchAvatar,
                                                                "branchAddress": item.branchAddress,
                                                                "branchPhone": item.branchPhone,
                                                                "branchOfCompany": item.branchOfCompany,
                                                                "branchManager": item.branchManager,
                                                                "branchTypeOfBusiness": item.branchTypeOfBusiness
                                                            });

                                                        }}>Chuyển đổi
                                                        </button>
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    )
                                    :
                                    <div className="col-12">
                                        <DataEmpty icon/>
                                    </div>
                                }
                            </Row>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

ListBranchForStaff.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};
const mapStateToProps = state => ({ settings: state.settings,  user: state.user,  menus: state.menus });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ListBranchForStaff));
