import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import configsReducer from './configs.reducers';
import themesReducer from './themes.reducers.js';
import userReducer from './user.reducers.js';
import menusReducer from './menus.reducers.js';
import toursReducer from './tours.reducers.js';

export default combineReducers({
    settings: settingsReducer,
    configs: configsReducer,
    theme: themesReducer,
    user: userReducer,
    menus: menusReducer,
    tours: toursReducer
});
